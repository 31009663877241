import s from "../LoginModal/Login.module.css"
import {useEffect, useState} from "react";
import {Button, ConfigProvider, Form, Input, Select} from "antd";
import {HeaderThemes} from "../../themes/HeaderThemes";
import {useSelector} from "react-redux";

const {Option} = Select;

const AskModal = ({setIsModalOpen, onCancel}) => {

    return (
        <div className={s.modal}>
            <AskForm setIsModalOpen={setIsModalOpen}/>
        </div>
    )
}
export default AskModal

const onFinishFailed = (errorInfo) => {

};
const AskForm = ({setIsModalOpen}) => {
    const [error, setError] = useState("")
    const {lang} = useSelector(state => state.lang);
    const {isAuthenticated} = useSelector(state => state.auth);
    const {id} = useSelector(state => state.user);

    let formdata = new FormData();

    useEffect(() => {
        if (!isAuthenticated) {
            setError(lang === 'ru' ? 'Вам необходимо войти в систему, чтобы отправить вопрос!' : 'You need to log in to send a question')
        } else {
            setError('')
        }
    }, [lang, isAuthenticated, setIsModalOpen]);


    return <ConfigProvider theme={HeaderThemes.loginForm}>
        <Form className={s.form}
              name="login"
              layout="vertical"
              onFinish={async (values) => {
                  formdata.append("question", values.question);
                  formdata.append("category", values.category);
                  formdata.append("country", values.country);
                  formdata.append("userId", id);
                  let requestOptions = {
                      method: 'POST',
                      headers: {
                          'Authorization': `Bearer ${localStorage.getItem("access")}`,
                      },
                      body: formdata,
                      redirect: 'follow',
                      withCredentials: true
                  }
                  if (isAuthenticated) {
                      try {
                          const response = await fetch("https://bricsyoung.com/api/forum", requestOptions);
                          const data = await response.json();
                          if (data.access) {

                          } else if (data.detail) {
                              setError(data.detail)
                          }
                      } catch (error) {

                      }
                  } else {

                  }
              }}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              requiredMark={false}
        >

            <Form.Item
                name="category"
                label={lang === 'ru' ? "Категория" : "Category"}
                rules={[
                    {
                        required: true,
                        message: lang === 'ru' ? 'Пожалуйста, выберите категорию!' : 'Please select the category!',
                    },
                ]}
            >
                <Select placeholder={lang === 'ru' ? 'Пожалуйста, выберите категорию' : "Please select the category"}>
                    <Option value="наука">{lang === 'ru' ? 'Наука' : 'Science'}</Option>
                    <Option value="молодежь">{lang === 'ru' ? 'Молодежь' : 'Youth'}</Option>
                    <Option value="мероприятия">{lang === 'ru' ? 'Мероприятия' : 'Events'}</Option>
                </Select>
            </Form.Item>
            <Form.Item
                label={lang === 'ru' ? "Вопрос" : "Question"}
                name="question"
                rules={[
                    {
                        required: true,
                        message: lang === 'ru' ? "Напишите вопрос!" : 'Write Your Question!',
                    },
                ]}
            >
                <Input.TextArea showCount maxLength={100}/>
            </Form.Item>
            <Form.Item
                name="country"
                label={lang === 'ru' ? "Страна" : "Country"}
                rules={[
                    {
                        required: true,
                        message: lang === 'ru' ? 'Пожалуйста, выберите свою страну!' : 'Please select your country!',
                    },
                ]}
            >
                <Select placeholder={lang === 'ru' ? 'Пожалуйста, выберите свою страну' : "Please select a country"}>
                    <Option value="Russia">{lang === 'ru' ? 'Россия' : 'Russia'}</Option>
                    <Option value="Saudi Arabia">{lang === 'ru' ? 'Саудовская Аравия' : 'Saudi Arabia'}</Option>
                    <Option
                        value="Republic of South Africa">{lang === 'ru' ? 'Южная Африка' : 'Republic of South Africa'}</Option>
                    <Option value="United Arab Emirates">{lang === 'ru' ? 'ОАЭ' : 'United Arab Emirates'}</Option>
                    <Option value="Brazil">{lang === 'ru' ? 'Бразилия' : 'Brazil'}</Option>
                    <Option value="China">{lang === 'ru' ? 'Китай' : 'China'}</Option>
                    <Option value="Egypt">{lang === 'ru' ? 'Египет' : 'Egypt'}</Option>
                    <Option value="Ethiopia">{lang === 'ru' ? 'Эфиопия' : 'Ethiopia'}</Option>
                    <Option value="India">{lang === 'ru' ? 'Индия' : 'India'}</Option>
                    <Option value="Iran">{lang === 'ru' ? 'Иран' : 'Iran'}</Option>
                    <Option value="Other">{lang === 'ru' ? 'Другое' : 'Other'}</Option>
                </Select>
            </Form.Item>
            <Form.Item
            >
                <div style={{marginTop: "30px"}} className={s.nav}>
                    <Button htmlType="submit">
                        {lang === 'ru' ? "Отправить" : 'Send'}
                    </Button>
                    {error && <div className={s.error}>{error}</div>}
                </div>

            </Form.Item>
        </Form>
    </ConfigProvider>
}
