import s from "./Gallery.module.css"
import {useSelector} from "react-redux";
import {Card, Col, Row} from "antd";
import React from "react";
import {FolderOpenOutlined} from "@ant-design/icons";
import {NavLink} from "react-router-dom";
import {format} from "date-fns";
import {enUS, ru} from "date-fns/locale";

const Gallery = () => {
    const {lang} = useSelector(state => state.lang);

    return (
        <div className={s.gallery}>
            <h1>
                {lang === 'ru' ? 'Фотобанк' : 'Gallery'}
            </h1>
            <FoldersPart/>
        </div>
    )
}
export default Gallery

const FoldersPart = ({load = true}) => {
    return (
        <Row gutter={[32, 32]}>
            {
                load ? folders.map(el => (
                    <Col xs={12} sm={8} md={8} lg={6} xl={4} key={el.id}>
                        <MiniFolder {...el}/>
                    </Col>
                )) : Array.from({length: 8}).map((_, index) => (
                    <Col span={6} key={index}>
                        <Card loading={true}/>
                    </Col>
                ))
            }
        </Row>
    )
}

const MiniFolder = ({date, url}) => {
    const {lang} = useSelector(state => state.lang);
    return (
        <div className={s.folder}>
            <NavLink to={url}>
                <FolderOpenOutlined style={{fontSize: "128px", fontWeight: "lighter"}}/>
                <div className={s.date}>
                    {formatFolderDate(date, lang)}
                </div>

            </NavLink>

        </div>
    )
}

export function formatFolderDate(dateString, lang) {
    if (dateString === '') {
        return ''
    }
    const date = new Date(dateString);
    return format(date, 'dd MMMM yyyy', {locale: lang === "ru" ? ru : enUS});
}

const folders = [
    {
        date: '2024-08-23',
        url: '/gallery/photo/2024-08-23'
    },
    {
        date: '2024-08-22',
        url: '/gallery/photo/2024-08-22'
    },
    {
        date: '2024-08-21',
        url: '/gallery/photo/2024-08-21'
    },
    {
        date: '2024-07-25',
        url: '/gallery/photo/2024-07-25'
    },
    {
        date: '2024-07-24',
        url: '/gallery/photo/2024-07-24'
    },
    {
        date: '2024-07-23',
        url: '/gallery/photo/2024-07-23'
    },
    {
        date: '2024-07-22',
        url: '/gallery/photo/2024-07-22'
    },
    {
        date: '2024-07-21',
        url: '/gallery/photo/2024-07-21'
    },
    
]