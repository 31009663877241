import s from "./News.module.css"
import {Card, Col, Row, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {NavLink} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {BlockTitleWithUrl} from "../../../Pages/News/SingleNewsPage";
import {format} from "date-fns";
import {enUS, ru} from "date-fns/locale";

const NewsBlock = () => {
    const [respData, setData] = useState('')
    const [load, setLoad] = useState(false)
    const {lang} = useSelector(state => state.lang);
    const isSmall = useMediaQuery({query: '(max-width: 768px)'})
    useEffect(() => {
        const fetchData = async () => {
            await axios.get("https://bricsyoung.com/api/science")
                .then((res) => {
                    setData(res.data)
                })
                .catch((e) => {
                })

        }
        fetchData().finally(() => setLoad(true))
    }, []);

    const response = Object.values(respData).map(function (item) {
            return {
                body: item.body,
                bodyen: item.bodyen,
                date: item.date || '',
                end_time: item.end_time || '',
                place: item.place || '',
                placeen: item.placeen || '',
                description: item.description,
                descriptionen: item.descriptionen,
                header: item.header,
                headeren: item.headeren,
                id: item.id || '',
                category: item.category,
                img: 'https://bricsyoung.com' + item.img
            }
        }
    ).slice(0, 4)

    return (
        <div className={s.news}>
            <div className={s.info}>
                <BlockTitleWithUrl size={isSmall ? "32px" : "60px"} ruTitle="Анонсы" enTitle="Announcements" url="/announcements"/>
                <EventsDisplay response={response} load={load} lang={lang}/>
            </div>
        </div>
    )
}

export const EventsDisplay = ({response, load = true}) => {
    return (
        <Row gutter={[32, 32]}>
            {
                load ? response.map(el => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={6} key={el.id}>
                        <EventsCard {...el} url={`/announcements/${el.id}`}/>
                    </Col>
                )) : Array.from({length: 8}).map((_, index) => (
                    <Col span={6} key={index}>
                        <Card loading={true}/>
                    </Col>
                ))
            }
        </Row>
    )
}
export function formatEventDate(dateString, lang) {
    if (dateString === '') {
        return ''
    }
    const date = new Date(dateString);
    return format(date, 'dd MMMM', {locale: lang === "ru" ? ru : enUS});
}

export const EventsCard = ({header, headeren, img, url, date, end_time, org = 'Организация', orgEn = 'Organization', place, placeen}) => {
    const {lang} = useSelector(state => state.lang);
    const [height, setHeight] = useState({
        maxHeight: "204px",
        border: "1px solid black",
        borderBottom: "none",
        width: "calc(100% - 2px)"
    });
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1201px)'
    })
    const isTabletOrMobile = useMediaQuery({minWidth: 769, maxWidth: 1200})
    const isSmall = useMediaQuery({query: '(max-width: 768px)'})

    useEffect(() => {
        if (isDesktopOrLaptop) {
            setHeight({
                maxHeight: "204px",
                border: "1px solid black",
                borderBottom: "none",
                width: "calc(100% - 2px)"
            })
        } else if (isTabletOrMobile) {
            setHeight(
                {maxHeight: "275px",
                    border: "1px solid black",
                    borderBottom: "none",
                    width: "calc(100% - 2px)"
                })
        } else if (isSmall) {
            setHeight({
                border: "1px solid black",
                borderBottom: "none",
                width: "calc(100% - 2px)"
            })
        }
    }, [isDesktopOrLaptop, isTabletOrMobile, isSmall]);

    return (
        <Tooltip title={lang === "ru" ? header : headeren}>
            <NavLink  to={url}>
                <Card className={s.card} cover={
                    <img src={img} style={height} alt=""/>
                }>

                    <div className={s.text}>
                        <p>
                            {lang === "ru" ? org : orgEn}
                        </p>

                        <h3 className={s.textNews}>{lang === "ru" ? header : headeren}</h3>

                    </div>
                    <div className={s.event}>
                        <span>
                            {formatEventDate(date, lang)} - {formatEventDate(end_time, lang)}
                        </span>
                        <span style={{color: "#888888", textAlign: "right"}}>
                            {lang === "ru" ? place : placeen}
                        </span>
                    </div>
                </Card>
            </NavLink>
        </Tooltip>
    )
}

export default NewsBlock