import s from "../../Components/LoginModal/Login.module.css"
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button, ConfigProvider, Modal} from "antd";
import {useSelector} from "react-redux";
import logo1 from "../../assets/Header/LoginLogo.svg";
import {HeaderThemes} from "../../themes/HeaderThemes";


const Activation = () => {

    const [errorM, setErrorM] = useState('Активация...')
    const {uid, token} = useParams();
    const {lang} = useSelector(state => state.lang);
    const [isModalVisible, setIsModalVisible] = useState(true);
    let navigate = useNavigate()


    useEffect(() => {
        let formData = new FormData();
        formData.append("uid", uid);
        formData.append("token", token);
        let myHeaders = new Headers();
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow',
            withCredentials: true
        }
        const baseURL = 'https://bricsyoung.com/api/'
        //login(requestOptions)
        fetch(baseURL + "auth/users/activation/", requestOptions)
            .then(response =>
                response.text()
            )
            .then((result) => {
                    let resp = result ? JSON.parse(result) : ''
                    if (resp !== '') {
                        setErrorM(resp.detail)
                    } else {
                        setErrorM(lang === 'ru' ? "Аккаунт активирован!" : "The account is activated!")

                    }
                }
            )
    }, []);
    const handleOk = () => {
        setIsModalVisible(false);
        navigate("/")
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        navigate("/")
    };

    return (
        <div>
            <Modal footer={() => <></>} title={<img src={logo1} alt=""/>} open={isModalVisible} onOk={handleOk}
                   onCancel={handleCancel}>
                <ConfigProvider theme={HeaderThemes.loginForm}>
                    <div className={s.info}>
                        <h2>
                            {errorM}
                        </h2>
                        <div style={{marginTop: "30px"}} className={s.nav}>
                            <Button onClick={handleOk}>
                                {lang === 'ru' ? "OK" : 'Submit'}
                            </Button>
                        </div>
                    </div>
                </ConfigProvider>

            </Modal>
        </div>
    )
}
export default Activation
