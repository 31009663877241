import s from "./Profile.module.css"
import {
    BankOutlined,
    EditOutlined,
    EnvironmentOutlined,
    LogoutOutlined,
    MailOutlined
} from "@ant-design/icons";
import {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import {Button, ConfigProvider} from "antd";
import {CabinetThemes} from "../../themes/CabinetThemes";
import {logout} from "../../Redux/auth/authActions";
import avatar from "../../assets/empty_avatar.png"

const Profile = () => {
    const {lang} = useSelector(state => state.lang);
    const {isAuthenticated} = useSelector(state => state.auth);
    let navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <MainProfile lang={lang}/>
            <BottomBlock lang={lang}/>
        </div>
    )
}

const MainProfile = ({lang}) => {
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.user);

    useEffect(() => {
        const fetchData = async (user) => {
            let config = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: 'https://bricsyoung.com/api/usersinfo',
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            };
            await axios.request(config)
                .then((response) => {
                    let userData = response.data.find(news => news.username === user.username);
                    setUserInfo(userData);
                })
                .catch(() => {
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        fetchData(user);
    }, [user]);

    if (loading) {
        return <div></div>;
    }

    if (!userInfo) {
        return <div></div>;
    }
    return (
        <div className={s.main}>

            <div className={s.info}>
                <h1>{userInfo.username}</h1>
                <p>{userInfo.имя} {userInfo.фамилия}</p>
                <p>{lang === "ru" ? 'Пол' : 'Sex'}: {userInfo.пол}</p>
            </div>
            <div className={s.data}>
                <h2>{lang === "ru" ? 'Данные' : 'Info'}</h2>
                <p><EnvironmentOutlined
                    style={{fontSize: "20px", paddingRight: "6px"}}/>{userInfo.страна}, {userInfo.город}</p>
                <p><MailOutlined
                    style={{fontSize: "20px", paddingRight: "6px"}}/>{userInfo.email}</p>
                <p><BankOutlined
                    style={{fontSize: "20px", paddingRight: "6px"}}/>{userInfo.организация}</p>
            </div>

            <img src={userInfo.фото_профиля? userInfo.фото_профиля : avatar} alt=""/>

        </div>
    )
}

const BottomBlock = ({lang}) => {
    return (
        <div className={s.bottom}>
            <BottomButton theme="edit" lang={lang}/>
            <BottomButton theme="logout" lang={lang}/>
        </div>
    )
}

const BottomButton = ({theme, lang}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    return (
        <ConfigProvider theme={theme === "edit" ? CabinetThemes.edit : CabinetThemes.logout}>
            {theme === "edit" ?
                <NavLink to="/edit"><Button
                    icon={<EditOutlined/>}>{lang === "ru" ? "Редактировать" : "Edit"}</Button></NavLink> :
                <div>
                    <Button onClick={() => {
                        dispatch(logout())
                        navigate("/")
                    }} icon={
                        <LogoutOutlined/>}>{lang === "ru" ? "Выйти из аккаунта" : "Logout"}</Button>
                </div>}

        </ConfigProvider>
    )
}

export default Profile