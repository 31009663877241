import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import s from "./Forms.module.css";

const EduHistoryForm = () => {
    const {lang} = useSelector(state => state.lang);
    const minHeight = useMediaQuery({query: '(min-width: 620px)'})
    const secondHeight = useMediaQuery({minWidth: 520, maxWidth: 619})
    const thirdHeight = useMediaQuery({minWidth: 390, maxWidth: 519})
    const lastHeight = useMediaQuery({query: '(max-width: 389px)'})

    return (
        <div className={s.form}>
            <h1>{lang === "ru" ? 'Расскажи о своей истории учёбы по обмену' : 'Tell us about your exchange student story'}</h1>
            <script src="https://forms.yandex.ru/_static/embed.js"></script>
            <iframe src="https://forms.yandex.ru/u/66e443012530c24ac012f492/?iframe=1" frameBorder="0"
                    name="ya-form-66e443012530c24ac012f492" height="900" width="100%"></iframe>
        </div>
    )
}
export default EduHistoryForm

function getHeight(min, second, third, last) {
    if (min) {
        return "5200"
    }
    if (second) {
        return "5500"
    }
    if (third) {
        return "6000"
    }
    if (last) {
        return "7200"
    }
}