import s from "../Gallery/Gallery.module.css"
import {useSelector} from "react-redux";
import {formatFolderDate} from "../Gallery";
import {Col, Row, Image, Tooltip, Card} from "antd";
import React, {useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import InfiniteScroll from "react-infinite-scroll-component";


const GalleryDatePage = ({folder, number, amount, loadCount, date = '2024-01-01'}) => {
    const {lang} = useSelector(state => state.lang);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);
    return (
        <div className={s.gallery}>
            <h1>
                {formatFolderDate(date, lang)}
            </h1>
            <PhotosDisplay folder={folder} number={number} amount={amount} loadCount={loadCount} load={load}/>
        </div>
    )
}
export default GalleryDatePage

const PhotosDisplay = ({folder, number, amount, loadCount}) => {
    const [photos, setPhotos] = useState(Array.from({length: loadCount}).map((_, index) => ({
        id: index,
        // title: "First Day",
        img: `https://bricsyoung.com/media/${folder}/${number}%20%28${index + 1}%29.jpg`
    })));
    const [hasMore, setHasMore] = useState(true);

    const fetchMoreData = () => {
        if (photos.length >= amount) {
            setHasMore(false);
            return;
        }

        // Имитация получения новых данных
        setTimeout(() => {
            setPhotos(prevPhotos => [
                ...prevPhotos,
                ...Array.from({length: loadCount}).map((_, index) => ({
                    id: prevPhotos.length + index,
                    // title: "First Day",
                    img: `https://bricsyoung.com/media/${folder}/${number}%20%28${prevPhotos.length + index + 1}%29.jpg`
                }))
            ]);
        }, 100);
    };

    return (
        <div style={{maxWidth: "1640px"}}>
            <InfiniteScroll
                dataLength={photos.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                style={{overflow: "none"}}
            >
                <Image.PreviewGroup
                    preview={{
                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                    }}
                >
                    <Row gutter={[32, 32]}>
                        {
                            photos.map((photo, index) => (
                                <Col xs={24} sm={24} md={12} lg={12} xl={6} key={index}>
                                    <PhotoBlock title={photo.title} img={photo.img}/>
                                </Col>
                            ))
                        }
                    </Row>
                </Image.PreviewGroup>
            </InfiniteScroll>
        </div>
    )
}

const PhotoBlock = ({img, title = ""}) => {
    const isSmall = useMediaQuery({query: '(max-width: 768px)'})

    return (
        <Tooltip title={title}>
            <div className={s.image}>
                <div className={s.upper}>
                    <Image
                        src={img}
                        placeholder={
                            <Card
                                loading={true}
                            />
                        }
                        height={isSmall ? {} : 260}/>
                </div>
            </div>
        </Tooltip>
    )
}