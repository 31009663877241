import s from "./Header.module.css"
import logo from "../../assets/Header/logo1.svg"
import {Button, ConfigProvider, Menu, Modal, Select} from "antd";
import {NavLink, useLocation} from "react-router-dom";
import {LoginOutlined, UserOutlined} from "@ant-design/icons";
import {setEnLanguage, setRuLanguage} from "../../Redux/language/langActions";
import {useDispatch, useSelector} from "react-redux";
import {HeaderThemes} from "../../themes/HeaderThemes"
import {useMediaQuery} from "react-responsive";
import {useState} from "react";
import logo1 from "../../assets/Header/LoginLogo.svg"
import LoginModal from "../LoginModal";
import {resetReg} from "../../Redux/auth/authActions";


const Header = () => {
    const {lang} = useSelector(state => state.lang);
    return (
        <header className={s.header}>
            <div className={s.top}>
                <LeftPart lang={lang}/>
                <RightPart lang={lang}/>
            </div>
            <NavBar lang={lang}/>
        </header>
    )
}
const LeftPart = ({lang}) => {
    const isSmall = useMediaQuery({query: '(max-width: 768px)'})
    return (
        <div className={s.left}>
            <NavLink to="/"><img src={logo} alt="brics"/></NavLink>
            {!isSmall &&
                <article>
                    {lang === "ru" ? "Коммуникационная молодежная площадка" : "Youth communication platform"}
                </article>
            }
        </div>
    )
}
const RightPart = ({lang}) => {
    return (
        <div className={s.right}>
            <Languages lang={lang}/>
            <LoginButton lang={lang}/>
        </div>
    )
}
const Languages = ({lang}) => {
    const dispatch = useDispatch();

    const handleChange = (value) => {
        const selectedLang = value;
        if (selectedLang === 'ru') {
            dispatch(setRuLanguage());
        } else if (selectedLang === 'en') {
            dispatch(setEnLanguage());
        }
    };
    const options = [
        {value: 'ru', label: 'RU'},
        {value: 'en', label: 'EN'}
    ];

    return (
        <ConfigProvider theme={HeaderThemes.languages}>
            <Select
                style={{
                    width: 100,
                    height: 52,
                    textAlign: 'center'
                }}
                defaultValue={
                    lang === 'ru' ? {
                            value: 'ru',
                            label: 'RU',
                        } :
                        {
                            value: 'en',
                            label: 'EN',
                        }
                }
                onChange={handleChange}
                options={options}
            />
        </ConfigProvider>
    );
}

const LoginButton = ({lang}) => {
    const {isAuthenticated} = useSelector(state => state.auth);
    const {username} = useSelector(state => state.user);
    const isMobile = useMediaQuery({query: '(max-width: 1200px)'})
    const isDesktop = useMediaQuery({query: '(min-width: 1201px)'})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const showModal = () => {
        setIsModalOpen(true);
    };
    const onCancel = () => {
        setIsModalOpen(false)
        dispatch(resetReg())
    }
    return (
        <span>
            {isMobile && <ConfigProvider theme={HeaderThemes.loginMobile}>
                {isAuthenticated ?
                    <NavLink to="/cabinet"><Button style={{width: "52px"}}
                                                   icon={<UserOutlined/>}></Button></NavLink> :
                    <div>
                        <Button onClick={showModal} style={{width: "52px"}} icon={
                            <LoginOutlined/>}></Button>
                    </div>}

            </ConfigProvider>}
            {isDesktop && <ConfigProvider theme={HeaderThemes.login}>
                {isAuthenticated ?
                    <NavLink to="/cabinet"><Button
                        icon={<UserOutlined/>}>{username}</Button></NavLink> :
                    <div style={{minWidth: "250px"}}>
                        <Button onClick={showModal} icon={
                            <LoginOutlined/>}>{lang === "ru" ? "Стать участником проекта" : "Become a project participant"}</Button>
                    </div>}

            </ConfigProvider>}
            <Modal footer={() => <></>} title={<img src={logo1} alt=""/>} open={isModalOpen}
                   onCancel={onCancel}
            >
                <LoginModal onCancel={onCancel} setIsModalOpen={setIsModalOpen}/>
            </Modal>
        </span>
    )
}


const NavBar = ({lang}) => {
    const location = useLocation();

    const items = [
        {
            key: "/",
            label: (
                <NavLink to="/">
                    Главная
                </NavLink>
            ),
        },
        {
            key: "/news",
            label: (
                <NavLink to="/news">
                    Новости
                </NavLink>
            ),
        },
        {
            key: "/announcements",
            label: (
                <NavLink to="/announcements">
                    Анонсы
                </NavLink>
            ),
        },
        {
            key: "/sport",
            label: (
                <NavLink to="/sport">
                    Спорт
                </NavLink>
            ),
        },
        {
            key: "/gallery",
            label: (
                <NavLink to="/gallery">
                    Галерея
                </NavLink>
            ),
            children: [
                { key: '/gallery/photo', label: <NavLink to="/gallery/photo">
                        Фотобанк
                    </NavLink> },
                { key: '/gallery/video', label: <NavLink to="/gallery/video">
                        Видео
                    </NavLink> }
            ],
        },
        {
            key: "/about",
            label: (
                <NavLink to="/about">
                    О проекте
                </NavLink>
            ),
        }
    ];

    const itemsEn = [
        {
            key: "/",
            label: (
                <NavLink to="/">
                    Main
                </NavLink>
            ),
        },
        {
            key: "/news",
            label: (
                <NavLink to="/news">
                    News
                </NavLink>
            ),
        },
        {
            key: "/announcements",
            label: (
                <NavLink to="/announcements">
                    Announcements
                </NavLink>
            ),
        },
        {
            key: "/sport",
            label: (
                <NavLink to="/sport">
                    Sport
                </NavLink>
            ),
        },
        {
            key: "/gallery",
            label: (
                <NavLink to="/gallery">
                    Gallery
                </NavLink>
            ),
            children: [
                { key: '/gallery/photo', label: <NavLink to="/gallery/photo">
                        Photo
                    </NavLink> },
                { key: '/gallery/video', label: <NavLink to="/gallery/video">
                        Video
                    </NavLink> }
            ],
        },
        {
            key: "/about",
            label: (
                <NavLink to="/about">
                    About the project
                </NavLink>
            ),
        }
    ];

    const currentItems = lang === 'ru' ? items : itemsEn;
    const getSelectedKey = (pathname) => {
        if (pathname.startsWith('/news')) return '/news';
        if (pathname.startsWith('/announcements')) return '/announcements';
        if (pathname.startsWith('/gallery')) return '/gallery';
        return pathname;
    };
    return (
        <div className={s.nav}>
            <ConfigProvider theme={HeaderThemes.nav}>
                <Menu
                    className="custom-submenu-title"
                    style={{justifyContent: "center"}}
                    mode="horizontal"
                    selectedKeys={[getSelectedKey(location.pathname)]}
                    items={currentItems}
                />
            </ConfigProvider>
        </div>
    );
};

export default Header