import s from './Banner.module.css'
import camera from '../../../assets/About/camera.svg'
import cameraMobile from '../../../assets/About/black-tv-broadcast-camera-floating-in-the-air-with 1.svg'
import {useMediaQuery} from "react-responsive";

const Banner = ({lang}) => {

    const isTabletOrMobile = useMediaQuery({maxWidth: 1200})
    const isBig = useMediaQuery({minWidth: 1920})
    return (
        <div className={s.banner}>
            {lang === 'ru' ? <h1 style={isBig ? {fontSize: "48px"} : {}}>
                    Медиаплатформа БРИКС — <span style={{fontWeight: "500"}}>пространство для молодёжного сотрудничества стран БРИКС</span>
                </h1> :
                <h1 style={isBig ? {fontSize: "48px"} : {}}>
                    BRICS Mediaplatform is <span style={{fontWeight: "500"}}>a space for youth cooperation between the BRICS countries</span>
                </h1>
            }
            <Column color="white" right={!isTabletOrMobile ? "235px" : "85px"}
                    top={!isTabletOrMobile ? "-250px" : "-280px"}/>
            <Column color="#0E6DAE" right={!isTabletOrMobile ? "166px" : "47px"}
                    top={!isTabletOrMobile ? "-192px" : "-248px"}/>
            <Column color="#e03028" right={!isTabletOrMobile ? "97px" : "9px"}
                    top={!isTabletOrMobile ? "-134px" : "-216px"}/>
            <Column color="#ea8b05" right={!isTabletOrMobile ? "28px" : "-29px"}
                    top={!isTabletOrMobile ? "-76px" : "-184px"}/>
            <Column color="#2da761" right={!isTabletOrMobile ? "-41px" : "-67px"}
                    top={!isTabletOrMobile ? "-18px" : "-152px"}/>
            <Column color="#f7c505" right={!isTabletOrMobile ? "-110px" : "-105px"}
                    top={!isTabletOrMobile ? "40px" : "-120px"}/>

            <img src={camera} alt=""/>
        </div>
    )
}
export default Banner

const Column = ({color, top, right}) => {
    return (
        <div className={s.column} style={{backgroundColor: color, top: top, right: right}}>

        </div>
    )
}

export const MobileBanner = ({lang}) => {
    return (
        <div className={s.banner}>
            <div className={s.mobile}>
                <img src={cameraMobile} alt=""/>
                <Column color="white" right={"170px"}
                        top={"-270px"}/>
                <Column color="#0E6DAE" right={"105px"}
                        top={"-235px"}/>
                <Column color="#e03028" right={"40px"}
                        top={"-200px"}/>
                <Column color="#ea8b05" right={"-25px"}
                        top={"-165px"}/>
                <Column color="#2da761" right={"-90px"}
                        top={"-130px"}/>
            </div>
            {lang === 'ru' ? <h1>
                    Медиаплатформа БРИКС — <span style={{fontWeight: "500"}}>пространство для молодёжного сотрудничества стран БРИКС</span>
                </h1> :
                <h1>
                    BRICS Mediaplatform is <span style={{fontWeight: "500"}}>a space for youth cooperation between the BRICS countries</span>
                </h1>}
        </div>
    )
}