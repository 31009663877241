import {SET_USER_DATA, SET_USER_DATA_ERROR} from '../types';

const initialState = {
    username: "",
    id: ""
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {username: action.payload.username, id: action.payload.id};
        case SET_USER_DATA_ERROR:
            return {
                username: "error",
                id: "error"
            };
        default:
            return state;
    }
};