import s from './About.module.css'
import Banner, {MobileBanner} from "../../Components/About/Banner";
import FourInfoBlocks, {FourInfoBlocksMobile} from "../../Components/About/FourInfoBlocks";
import HistoryBlock from "../../Components/About/HistoryBlock";
import PartnersBlock from "../../Components/About/PartnersBlock";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {useEffect} from "react";

const AboutUs = () => {
    const isSmall = useMediaQuery({query: '(max-width: 768px)'})

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            {!isSmall && <AboutUsDefault/>}
            {isSmall && <AboutUsMobile/>}

        </div>

    )
}
export default AboutUs

const AboutUsDefault = () => {
    const {lang} = useSelector(state => state.lang);
    return (
        <div>
            <div className={s.root}>
                <Banner lang={lang}/>
                <FourInfoBlocks lang={lang}/>
                <HistoryBlock lang={lang}/>
            </div>
            <PartnersBlock lang={lang}/>
        </div>
    )
}

const AboutUsMobile = () => {
    const {lang} = useSelector(state => state.lang);
    return (
        <div>
            <MobileBanner lang={lang}/>
            <FourInfoBlocksMobile lang={lang}/>

            <HistoryBlock lang={lang}/>
            <PartnersBlock lang={lang}/>
        </div>
    )
}