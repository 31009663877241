
import {SET_EN, SET_RU} from "../types";

export const setRuLanguage = () => {
    return dispatch => {
        dispatch({type: SET_RU});
    }
};
export const setEnLanguage = () => {
    return dispatch => {
        dispatch({type: SET_EN});
    }
};
