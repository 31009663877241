import s from "../../Components/LoginModal/Login.module.css"
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Button, ConfigProvider, Form, Input, Modal} from "antd";
import {HeaderThemes} from "../../themes/HeaderThemes";
import logo1 from "../../assets/Header/LoginLogo.svg";


const NewPassword = () => {

    const [errorM, setErrorM] = useState('')
    const {uid, token} = useParams();
    const [enter, setEnter] = useState(false)
    const {lang} = useSelector(state => state.lang);
    const [isModalVisible, setIsModalVisible] = useState(true);
    let navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handleOk = () => {
        setIsModalVisible(false);
        navigate("/")
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        navigate("/")
    };

    if (enter === true) {

        return (
            <div>
                <Modal footer={() => <></>} title={<img src={logo1} alt=""/>} open={isModalVisible} onOk={handleOk}
                       onCancel={handleCancel}>
                    <ConfigProvider theme={HeaderThemes.loginForm}>
                        <div className={s.info}>
                            <h2>
                                {lang === 'ru' ? 'Пароль был изменен!' : 'The password has been changed!'}
                            </h2>
                            <div style={{marginTop: "30px"}} className={s.nav}>
                                <Button onClick={handleOk}>
                                    {lang === 'ru' ? "Войти" : 'Submit'}
                                </Button>
                            </div>
                        </div>
                    </ConfigProvider>
                </Modal>
            </div>
        )
    }

    return (
        <div>
            <Modal footer={() => <></>} title={<img src={logo1} alt=""/>} open={isModalVisible} onOk={handleOk}
                   onCancel={handleCancel}>
                <ConfigProvider theme={HeaderThemes.loginForm}>
                    <Form className={s.form}
                          onFinishFailed={onFinishFailed}
                          name="basic"
                          layout="vertical"
                          onFinish={async (values) => {
                              let formData = new FormData();
                              formData.append("uid", uid);
                              formData.append("token", token);
                              formData.append("new_password", values.password);
                              formData.append("re_new_password", values.password);
                              let myHeaders = new Headers();
                              let requestOptions = {
                                  method: 'POST',
                                  headers: myHeaders,
                                  body: formData,
                                  redirect: 'follow',
                                  withCredentials: true
                              }
                              const baseURL = 'https://bricsyoung.com/api/'
                              //login(requestOptions)
                              fetch(baseURL + "auth/users/reset_password_confirm/", requestOptions)
                                  .then(response =>
                                      response.text()
                                  )
                                  .then((result) => {
                                          let resp = result ? JSON.parse(result) : ''

                                          if (resp && resp !== '') {
                                              if (resp.new_password) {
                                                  setErrorM(resp.new_password)
                                              } else {
                                                  setErrorM(lang === 'ru' ? "Что-то пошло не так! Попробуйте поменять введенные данные или повторите попытку позже" : "Error! Try changing the entered data or try again later")
                                              }
                                          } else {
                                              setEnter(true)
                                          }
                                      }
                                  )
                          }}
                          autoComplete="off"
                          requiredMark={false}
                    >
                        <Form.Item
                            name="password"
                            label={lang === 'ru' ? "Пароль" : "Password"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label={lang === 'ru' ? "Подтвердите пароль" : "Confirm Password"}
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: lang === 'ru' ? "Подтвердите пароль!" :'Please confirm your password!',
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(lang === 'ru' ? "Пароли должны совпадать!" : 'The new password that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item

                        >
                            <div style={{marginTop: "30px"}} className={s.nav}>
                                <Button htmlType="submit">
                                    {lang === 'ru' ? "Войти" : 'Submit'}
                                </Button>
                                {errorM && <div className={s.error}>{errorM}</div>}
                            </div>

                        </Form.Item>
                    </Form>
                </ConfigProvider>
            </Modal>
        </div>
    )
}
const onFinishFailed = (errorInfo) => {

};
export default NewPassword