import s from "./News.module.css"
import {Card, Col, ConfigProvider, Row, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {NavLink} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {format} from 'date-fns';
import {useSelector} from "react-redux";
import {ru, enUS} from 'date-fns/locale';
import {BlockTitleWithUrl} from "../../../Pages/News/SingleNewsPage";
import {MainThemes} from "../../../themes/MainThemes";

const NewsBlock = () => {
    const [respData, setData] = useState('')
    const [load, setLoad] = useState(false)
    const {lang} = useSelector(state => state.lang);
    const isSmall = useMediaQuery({query: '(max-width: 768px)'})
    useEffect(() => {
        const fetchData = async () => {
            await axios.get("https://bricsyoung.com/api/news")
                .then((res) => {
                    setData(res.data)
                })
                .catch((e) => {
                })

        }
        fetchData().finally(() => setLoad(true))
    }, []);

    const response = Object.values(respData).map(function (item) {
            return {
                body: item.body,
                bodyen: item.bodyen,
                date: item.date,
                description: item.description,
                descriptionen: item.descriptionen,
                header: item.header,
                headeren: item.headeren,
                id: item.id,
                category: item.category,
                img: 'https://bricsyoung.com' + item.img
            }
        }
    ).slice(0, 4)

    return (
        <div className={s.news}>
            <div className={s.info}>
                <BlockTitleWithUrl size={isSmall ? "32px" : "60px"} ruTitle="Новости" enTitle="News" url="/news"/>
                <NewsDisplay response={response} load={load} lang={lang}/>
            </div>
        </div>
    )
}

export const NewsDisplay = ({response, load = true, lang, url = "/news/"}) => {
    return (
        <Row gutter={[32, 32]}>
            {
                load ? response.map(el => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={6} key={el.id}>
                        <NewsCard {...el} url={`${url + el.id}`}/>
                    </Col>
                )) : Array.from({length: 8}).map((_, index) => (
                    <Col span={6} key={index}>
                        <Card loading={true}/>
                    </Col>
                ))
            }
        </Row>
    )
}

export const NewsCard = ({header, headeren, img, url, date, category = "новости"}) => {
    const {lang} = useSelector(state => state.lang);
    const [height, setHeight] = useState({
        maxHeight: "204px",
        border: "1px solid black",
        borderBottom: "none",
        width: "calc(100% - 2px)"
    });
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1201px)'
    })
    const isTabletOrMobile = useMediaQuery({minWidth: 769, maxWidth: 1200})
    const isSmall = useMediaQuery({query: '(max-width: 768px)'})

    useEffect(() => {
        if (isDesktopOrLaptop) {
            setHeight({
                maxHeight: "204px",
                border: "1px solid black",
                borderBottom: "none",
                width: "calc(100% - 2px)"
            })
        } else if (isTabletOrMobile) {
            setHeight(
                {
                    maxHeight: "275px",
                    border: "1px solid black",
                    borderBottom: "none",
                    width: "calc(100% - 2px)"
                })
        } else if (isSmall) {
            setHeight({
                border: "1px solid black",
                borderBottom: "none",
                width: "calc(100% - 2px)"
            })
        }
    }, [isDesktopOrLaptop, isTabletOrMobile, isSmall]);

    return (
        <Tooltip title={lang === "ru" ? header : headeren}>
            <NavLink to={url}>
                <ConfigProvider theme={MainThemes.news}>
                    <Card className={s.card} cover={
                        <img src={img} style={height} alt={lang === "ru" ? header : headeren}/>
                    }>
                        <div className={s.text}>
                            <p>
                                {formatDate(date, lang)}
                                <span style={{textAlign: "right"}}>
                            {formatCategory(category, lang)}
                        </span>
                            </p>

                            <h3 className={s.textNews}>{lang === "ru" ? header : headeren}</h3>

                        </div>
                    </Card>
                </ConfigProvider>
            </NavLink>
        </Tooltip>
    )
}

export function formatDate(dateString, lang) {
    const date = new Date(dateString);
    return format(date, 'dd MMMM yyyy', {locale: lang === "ru" ? ru : enUS});
}

function formatCategory(string, lang) {
    if (lang === 'ru') {
        switch (string) {
            case "новости":
                return "Новость";
            case "молодёжное":
                return "Молодёжное сотрудничество";
            case "наука":
                return "Наука";
            case "образование":
                return "Образование";
        }
    } else {
        switch (string) {
            case "новости":
                return "News";
            case "молодёжное":
                return "Youth cooperation";
            case "наука":
                return "Science";
            case "образование":
                return "Education";
        }
    }

}

export default NewsBlock