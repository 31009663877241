import s from "./InfoBlocks.module.css"
import {useSelector} from "react-redux";
import {info} from "./info";
import paper from "../../../assets/About/newspaper 2.svg"

const FourInfoBlocks = ({lang}) => {

    return (
        <div>
            <div className={s.three}>
                <div className={s.left}>
                    {info.slice(0, 2).map(el =>
                        <LeftBlock {...el} lang={lang}/>
                    )}
                </div>
                <RightBlock info={info} lang={lang}/>
            </div>
            <InTouch lang={lang}/>
        </div>

    )
}
export default FourInfoBlocks

export const LeftBlock = ({lang, color, title, enTitle, description, enDescription}) => {
    return (
        <div className={color === 'black' ? `${s.block} ${s.black}` : `${s.block} ${s.white}`}>
            <h2>
                {lang === 'ru' ? title : enTitle}
            </h2>
            {lang === 'ru' ? description : enDescription}
        </div>
    )
}

const RightBlock = ({info, lang}) => {

    return (
        <div className={s.write}>
            <img src={paper} alt=""/>
            <LeftBlock {...info[2]} lang={lang}/>
        </div>
    )
}
const InTouch = ({lang}) => {
    return (
        <div className={s.touch}>
            <LeftBlock {...info[3]} lang={lang}/>
        </div>
    )
}

export const FourInfoBlocksMobile = () => {
    const {lang} = useSelector(state => state.lang);

    return (
        <div className={s.three}>
            {info.map((el) =>
                <LeftBlock {...el} lang={lang}/>
            )}
        </div>
    )
}