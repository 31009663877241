import s from "./Footer.module.css"
import {NavLink} from "react-router-dom";
import logo from "../../assets/Header/logo1.svg"
import youtube from "../../assets/Footer/YT.svg"
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import tg from "../../assets/Footer/TG.svg"
import vk from "../../assets/Footer/vk.svg"

const Footer = () => {
    const isMobile = useMediaQuery({query: '(max-width: 768px)'})
    const isDesktop = useMediaQuery({query: '(min-width: 769px)'})
    return (
        <>
            {isDesktop && <BigFooter/>}
            {isMobile && <SmallFooter/>}
        </>
    )
}
export default Footer

const InfoBlock = ({lang, title, enTitle, email, url}) => {
    return (
        <div className={s.infoBlock}>
            <h4>
                {lang === "ru" ? title : enTitle}
            </h4>
            <div>
                <a target="_blank" href={`mailto:${email}`}>
                    E-mail: {email}
                </a>
            </div>
            <div>
                <a target="_blank" href={`https://vk.com/${url}`}>
                    <img src={vk} alt=""/>vk.com/{url}
                </a>
            </div>

            <div>
                <a target="_blank" href={`https://t.me/${url}`}>
                    <img src={tg} alt=""/>t.me/{url}
                </a>
            </div>
        </div>
    )
}

const YouTubeButton = ({lang}) => {
    return (
        <NavLink className={s.yt} to="/">
            <img src={youtube} alt="YT"/>
            <div className={s.ytText}>
                {lang === "ru" ? 'Смотрите трансляции мероприятий на нашем ютуб-канале' : 'Watch live events on our YouTube channel'}

            </div>
        </NavLink>
    )
}
const SiteTitle = ({lang}) => {
    return (<div className={s.sideTitle}>
        <p>
            <a target="_blank" href={lang === "ru" ? "https://bricsyoung.com/media/media/behavior/2_Политика_перс._данных_БРИКС_1.pdf": "https://bricsyoung.com/media/media/behavior/Политика_обработки_персональных_данных_на_английском.docx"}>
                
        {lang === "ru" ? 'Политика обработки персональных данных' : 'Personal data processing policy'}
            </a>
        </p>
        <div >
            Коммуникационная молодежная площадка "Медиаплатформа БРИКС" Ⓒ 2024
        </div>
        <p >
        </p>
            Designed by Smart Mir Services LLC
    </div>
    )
}
const BigFooter = () => {
    const {lang} = useSelector(state => state.lang);
    return (
        <footer className={s.footer}>
            <div className={s.info}>


                <div className={s.upper}>
                    <div className={s.logo}>
                        <NavLink to="/">
                            <img src={logo} alt=""/>
                        </NavLink>
                    </div>
                    <div className={s.nav}>
                        <NavLink to="/">
                            {lang === "ru" ? 'Главная' : 'Main'}
                        </NavLink>
                        <NavLink to="/news">
                            {lang === "ru" ? 'Новости' : 'News'}
                        </NavLink>
                        <NavLink to="/announcements">
                            {lang === "ru" ? 'Анонсы' : 'Announcements'}
                        </NavLink>
                        <NavLink to="/about">
                            {lang === "ru" ? 'О проекте' : 'About'}
                        </NavLink>
                    </div>
                    {/*<div className={s.social}>*/}
                    {/*    <a href="#">*/}
                    {/*        <img src={yt} alt=""/>*/}
                    {/*    </a>*/}
                    {/*    <a href="#">*/}
                    {/*        <img src={fb} alt=""/>*/}
                    {/*    </a>*/}
                    {/*    <a href="#">*/}
                    {/*        <img src={inst} alt=""/>*/}
                    {/*    </a>*/}
                    {/*    <a href="#">*/}
                    {/*        <img src={twit} alt=""/>*/}
                    {/*    </a>*/}
                    {/*    <a href="https://t.me/youthbrics">*/}
                    {/*        <img src={tg} alt=""/>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
                {/*<div className={s.bottom}>*/}
                {/*    <NavLink to="#">*/}
                {/*        {lang === "ru" ? 'Политика конфиденциальности' : 'Privacy Policy'}*/}
                {/*    </NavLink>*/}
                {/*    <span>*/}
                {/*        {lang === "ru" ? 'Ⓒ 2024 БРИКС Медиапортал' : 'Ⓒ 2024 BRICS Media Platform'}*/}
                {/*    </span>*/}
                {/*</div>*/}
                <div className={s.bottom}>
                    <div className={s.infoLeft}>
                        <InfoBlock lang={lang}
                                   title="Центр международного сотрудничества Министерства просвещения Российской Федерации"
                                   enTitle="The Center for International Cooperation of the Ministry of Education of the Russian Federation"
                                   email="info@cic-edu.ru"
                                   url="cms_project"/>
                        <InfoBlock lang={lang}
                                   title="Проектный офис международного молодёжного сотрудничества “Россия – БРИКС”"
                                   enTitle="Project office for international youth cooperation “Russia – BRICS”"
                                   email="youthbrics73@yandex.ru"
                                   url="youthbrics"/>
                    </div>
                    <YouTubeButton lang={lang}/>
                </div>
                <SiteTitle lang={lang}/>
            </div>
        </footer>
    )
}

const SmallFooter = () => {
    const {lang} = useSelector(state => state.lang);
    return (
        <footer className={s.footer}>
            <div className={s.info}>
                <div className={s.upper}>
                    <div className={s.logo}>
                        <NavLink to="/">
                            <img src={logo} alt=""/>
                        </NavLink>
                    </div>
                </div>
                <YouTubeButton lang={lang}/>
                <InfoBlock lang={lang}
                           title="Центр международного сотрудничества Министерства просвещения Российской Федерации"
                           enTitle="Center for International Cooperation under the Ministry of Education of Russia"
                           email="info@cic-edu.ru"
                           url="cms_project"/>
                <InfoBlock lang={lang}
                           title="Проектный офис международного молодёжного сотрудничества “Россия – БРИКС”"
                           enTitle="Project office for international youth cooperation “Russia – BRICS”"
                           email="youthbrics73@yandex.ru"
                           url="youthbrics"/>
                <SiteTitle/>
            </div>
        </footer>
    )
}