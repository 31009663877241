import s from "../ResetPasswordPages/Reset.module.css";
import * as Yup from 'yup';
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import empty from "../../assets/empty_avatar.png";
import { useSelector } from "react-redux";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ProfileEdit = () => {
    let navigate = useNavigate();
    const [data, setData] = useState('');
    const { lang } = useSelector(state => state.lang);
    const [send, setSend] = useState('Отправить');
    const [avatar, setAvatar] = useState(null);
    const [avatarPreview, setAvatarPreview] = useState('');
    const [crop, setCrop] = useState({ aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState('');

    const { isAuthenticated } = useSelector(state => state.auth);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (lang === 'ru') {
            setSend("Отправить");
        }
        if (lang === 'en') {
            setSend("Send");
        }
    }, [lang]);

    useEffect(() => {
        let config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: 'https://bricsyoung.com/api/usersdata',
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem("access")}`,
            }
        };
        axios.request(config)
            .then((response) => {
                setData(response.data);
                setAvatarPreview(response.data.фото_профиля);
            });
    }, []);

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setAvatar(file);
            setAvatarPreview(URL.createObjectURL(file));
        }
    };


    const InfoSchema = Yup.object().shape({
        firstName: Yup.string()
            .max(50, lang === 'ru' ? 'Превышен лимит символов!' : 'Character limit exceeded')
            .required('Поле пустое!'),
        lastName: Yup.string()
            .max(50, lang === 'ru' ? 'Превышен лимит символов!' : 'Character limit exceeded')
            .required(lang === 'ru' ? 'Поле пустое!' : 'The Field Is Empty!'),
        country: Yup.string().required(lang === 'ru' ? 'Поле пустое!' : 'The Field Is Empty!'),
        sex: Yup.string().required(lang === 'ru' ? 'Поле пустое!' : 'The Field Is Empty!'),
        city: Yup.string().required(lang === 'ru' ? 'Поле пустое!' : 'The Field Is Empty!'),
        organization: Yup.string().required(lang === 'ru' ? 'Поле пустое!' : 'The Field Is Empty!'),
    });

    return (
        <div className={s.main}>
            <h1>Редактирование данных</h1>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: data.имя,
                    lastName: data.фамилия,
                    sex: data.пол,
                    country: data.страна,
                    city: data.город,
                    organization: data.организация
                }}
                onSubmit={(values) => {
                    let data1 = new FormData();
                    data1.append('имя', values.firstName);
                    data1.append('фамилия', values.lastName);
                    data1.append('пол', values.sex);
                    data1.append('страна', values.country);
                    data1.append('город', values.city);
                    data1.append('организация', values.organization);
                    if (avatar) {
                        data1.append('фото_профиля', avatar);
                    }

                    let config = {
                        method: 'patch',
                        maxBodyLength: Infinity,
                        url: 'https://bricsyoung.com/api/usersdata',
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'Authorization': `Bearer ${localStorage.getItem("access")}`,
                        },
                        data: data1
                    };
                    if (lang === 'ru') {
                        setSend('Отправка...');
                    }
                    if (lang === 'en') {
                        setSend('Sending...');
                    }

                    axios
                        .request(config)
                        .then(() => {
                            if (lang === 'ru') {
                                setSend('Отправить...');
                            }
                            if (lang === 'en') {
                                setSend('Send...');
                            }
                            navigate("/cabinet");
                        });
                }}
                validationSchema={InfoSchema}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form className={s.edit}>
                        <label>
                            <div>
                                {lang === 'ru' ? 'Аватар' : 'Avatar'}
                            </div>
                            <div className={s.avatarContainer}>
                                <img src={avatarPreview !== null ? avatarPreview : empty} alt="" className={s.avatar}/>
                                <input type="file" onChange={handleAvatarChange} accept="image/*"/>

                            </div>
                        </label>
                        <label>
                        <div>
                                {lang === 'ru' ? 'Имя' : 'First Name'}
                            </div>
                            <div>
                                <Field
                                    required={true}
                                    style={isWrong(errors.firstName, touched.firstName)}
                                    name="firstName"/>
                            </div>
                            {errors.firstName && touched.firstName ? (
                                <div className={s.error}>{errors.firstName}</div>
                            ) : null}
                        </label>
                        <label>
                            <div>
                                {lang === 'ru' ? 'Фамилия' : 'Second Name'}
                            </div>
                            <div>
                                <Field
                                    required={true}
                                    style={isWrong(errors.lastName, touched.lastName)}
                                    name="lastName"/>
                            </div>
                            {errors.lastName && touched.lastName ? (
                                <div className={s.error}>{errors.lastName}</div>
                            ) : null}
                        </label>
                        <label>
                            <div>
                                {lang === 'ru' ? 'Пол' : 'Sex'}
                            </div>
                            <div>
                                <Field as="select"
                                       required={true}
                                       style={isWrong(errors.sex, touched.sex)}
                                       name="sex">
                                    <option disabled selected value="">Выберите пол</option>
                                    <option value="Муж">{lang === 'ru' ? 'Мужской' : 'Male'}</option>
                                    <option value="Жен">{lang === 'ru' ? 'Женский' : 'Female'}</option>
                                </Field>
                            </div>
                            {errors.sex && touched.sex ? (
                                <div className={s.error}>{errors.sex}</div>
                            ) : null}
                        </label>
                        <label>
                            <div>
                                {lang === 'ru' ? 'Страна' : 'Country'}
                            </div>
                            <div>
                                <Field as="select"
                                       required={true}
                                       style={isWrong(errors.country, touched.country)}
                                       name="country">
                                    <option disabled selected
                                            value="">{lang === 'ru' ? 'Выберите страну' : 'Select a country'}</option>
                                    <option value="Австралия">{lang === 'ru' ? 'Австралия' : 'Australia'}</option>
                                    <option value="Австрия">{lang === 'ru' ? 'Австрия' : 'Austria'}</option>
                                    <option value="Азербайджан">{lang === 'ru' ? 'Азербайджан' : 'Azerbaijan'}</option>
                                    <option value="Албания">{lang === 'ru' ? 'Албания' : 'Albania'}</option>
                                    <option value="Алжир">{lang === 'ru' ? 'Алжир' : 'Algeria'}</option>
                                    <option
                                        value="Американское Самоа">{lang === 'ru' ? 'Американское Самоа' : 'American Samoa'}</option>
                                    <option value="Ангилья">{lang === 'ru' ? 'Ангилья' : 'Anguilla'}</option>
                                    <option value="Ангола">{lang === 'ru' ? 'Ангола' : 'Angola'}</option>
                                    <option value="Андорра">{lang === 'ru' ? 'Андорра' : 'Andorra'}</option>
                                    <option
                                        value="Антигуа и Барбуда">{lang === 'ru' ? 'Антигуа и Барбуда' : 'Antigua and Barbuda'}</option>
                                    <option value="Аргентина">{lang === 'ru' ? 'Аргентина' : 'Argentina'}</option>
                                    <option value="Армения">{lang === 'ru' ? 'Армения' : 'Armenia'}</option>
                                    <option value="Аруба">{lang === 'ru' ? 'Аруба' : 'Aruba'}</option>
                                    <option value="Афганистан">{lang === 'ru' ? 'Афганистан' : 'Afghanistan'}</option>
                                    <option value="Багамы">{lang === 'ru' ? 'Багамы' : 'Bahamas'}</option>
                                    <option value="Бангладеш">{lang === 'ru' ? 'Бангладеш' : 'Bangladesh'}</option>
                                    <option value="Барбадос">{lang === 'ru' ? 'Барбадос' : 'Barbados'}</option>
                                    <option value="Бахрейн">{lang === 'ru' ? 'Бахрейн' : 'Bahrain'}</option>
                                    <option value="Беларусь">{lang === 'ru' ? 'Беларусь' : 'Belarus'}</option>
                                    <option value="Белиз">{lang === 'ru' ? 'Белиз' : 'Belize'}</option>
                                    <option value="Бельгия">{lang === 'ru' ? 'Бельгия' : 'Belgium'}</option>
                                    <option value="Бенин">{lang === 'ru' ? 'Бенин' : 'Benin'}</option>
                                    <option value="Бермуды">{lang === 'ru' ? 'Бермуды' : 'Bermuda'}</option>
                                    <option value="Болгария">{lang === 'ru' ? 'Болгария' : 'Bulgaria'}</option>
                                    <option value="Боливия">{lang === 'ru' ? 'Боливия' : 'Bolivia'}</option>
                                    <option
                                        value="Бонайре, Синт-Эстатиус и Саба">{lang === 'ru' ? 'Бонайре, Синт-Эстатиус и Саба' : 'Bonaire, Sint Eustatius and Saba'}</option>
                                    <option
                                        value="Босния и Герцеговина">{lang === 'ru' ? 'Босния и Герцеговина' : 'Bosnia and Herzegovina'}</option>
                                    <option value="Ботсвана">{lang === 'ru' ? 'Ботсвана' : 'Botswana'}</option>
                                    <option value="Бразилия">{lang === 'ru' ? 'Бразилия' : 'Brazil'}</option>
                                    <option
                                        value="Бруней-Даруссалам">{lang === 'ru' ? 'Бруней-Даруссалам' : 'Brunei Darussalam'}</option>
                                    <option
                                        value="Буркина-Фасо">{lang === 'ru' ? 'Буркина-Фасо' : 'Burkina Faso'}</option>
                                    <option value="Бурунди">{lang === 'ru' ? 'Бурунди' : 'Burundi'}</option>
                                    <option value="Бутан">{lang === 'ru' ? 'Бутан' : 'Bhutan'}</option>
                                    <option value="Вануату">{lang === 'ru' ? 'Вануату' : 'Vanuatu'}</option>
                                    <option value="Ватикан">{lang === 'ru' ? 'Ватикан' : 'Vatican'}</option>
                                    <option
                                        value="Великобритания">{lang === 'ru' ? 'Великобритания' : 'United Kingdom'}</option>
                                    <option value="Венгрия">{lang === 'ru' ? 'Венгрия' : 'Hungary'}</option>
                                    <option value="Венесуэла">{lang === 'ru' ? 'Венесуэла' : 'Venezuela'}</option>
                                    <option
                                        value="Виргинские острова, Британские">{lang === 'ru' ? 'Виргинские острова, Британские' : 'British Virgin Islands'}</option>
                                    <option
                                        value="Виргинские острова, США">{lang === 'ru' ? 'Виргинские острова, США' : 'U.S. Virgin Islands'}</option>
                                    <option
                                        value="Восточный Тимор">{lang === 'ru' ? 'Восточный Тимор' : 'East Timor'}</option>
                                    <option value="Вьетнам">{lang === 'ru' ? 'Вьетнам' : 'Vietnam'}</option>
                                    <option value="Габон">{lang === 'ru' ? 'Габон' : 'Gabon'}</option>
                                    <option value="Гаити">{lang === 'ru' ? 'Гаити' : 'Haiti'}</option>
                                    <option value="Гайана">{lang === 'ru' ? 'Гайана' : 'Guyana'}</option>
                                    <option value="Гамбия">{lang === 'ru' ? 'Гамбия' : 'Gambia'}</option>
                                    <option value="Гана">{lang === 'ru' ? 'Гана' : 'Ghana'}</option>
                                    <option value="Гваделупа">{lang === 'ru' ? 'Гваделупа' : 'Guadeloupe'}</option>
                                    <option value="Гватемала">{lang === 'ru' ? 'Гватемала' : 'Guatemala'}</option>
                                    <option value="Гвинея">{lang === 'ru' ? 'Гвинея' : 'Guinea'}</option>
                                    <option
                                        value="Гвинея-Бисау">{lang === 'ru' ? 'Гвинея-Бисау' : 'Guinea-Bissau'}</option>
                                    <option value="Германия">{lang === 'ru' ? 'Германия' : 'Germany'}</option>
                                    <option value="Гибралтар">{lang === 'ru' ? 'Гибралтар' : 'Gibraltar'}</option>
                                    <option value="Гондурас">{lang === 'ru' ? 'Гондурас' : 'Honduras'}</option>
                                    <option value="Гонконг">{lang === 'ru' ? 'Гонконг' : 'Hong Kong'}</option>
                                    <option value="Гренада">{lang === 'ru' ? 'Гренада' : 'Grenada'}</option>
                                    <option value="Гренландия">{lang === 'ru' ? 'Гренландия' : 'Greenland'}</option>
                                    <option value="Греция">{lang === 'ru' ? 'Греция' : 'Greece'}</option>
                                    <option value="Грузия">{lang === 'ru' ? 'Грузия' : 'Georgia'}</option>
                                    <option value="Гуам">{lang === 'ru' ? 'Гуам' : 'Guam'}</option>
                                    <option value="Дания">{lang === 'ru' ? 'Дания' : 'Denmark'}</option>
                                    <option value="Джибути">{lang === 'ru' ? 'Джибути' : 'Djibouti'}</option>
                                    <option value="Доминика">{lang === 'ru' ? 'Доминика' : 'Dominica'}</option>
                                    <option
                                        value="Доминиканская Республика">{lang === 'ru' ? 'Доминиканская Республика' : 'Dominican Republic'}</option>
                                    <option value="Египет">{lang === 'ru' ? 'Египет' : 'Egypt'}</option>
                                    <option value="Замбия">{lang === 'ru' ? 'Замбия' : 'Zambia'}</option>
                                    <option
                                        value="Западная Сахара">{lang === 'ru' ? 'Западная Сахара' : 'Western Sahara'}</option>
                                    <option value="Зимбабве">{lang === 'ru' ? 'Зимбабве' : 'Zimbabwe'}</option>
                                    <option value="Израиль">{lang === 'ru' ? 'Израиль' : 'Israel'}</option>
                                    <option value="Индия">{lang === 'ru' ? 'Индия' : 'India'}</option>
                                    <option value="Индонезия">{lang === 'ru' ? 'Индонезия' : 'Indonesia'}</option>
                                    <option value="Иордания">{lang === 'ru' ? 'Иордания' : 'Jordan'}</option>
                                    <option value="Ирак">{lang === 'ru' ? 'Ирак' : 'Iraq'}</option>
                                    <option value="Иран">{lang === 'ru' ? 'Иран' : 'Iran'}</option>
                                    <option value="Ирландия">{lang === 'ru' ? 'Ирландия' : 'Ireland'}</option>
                                    <option value="Исландия">{lang === 'ru' ? 'Исландия' : 'Iceland'}</option>
                                    <option value="Испания">{lang === 'ru' ? 'Испания' : 'Spain'}</option>
                                    <option value="Италия">{lang === 'ru' ? 'Италия' : 'Italy'}</option>
                                    <option value="Йемен">{lang === 'ru' ? 'Йемен' : 'Yemen'}</option>
                                    <option value="Кабо-Верде">{lang === 'ru' ? 'Кабо-Верде' : 'Cape Verde'}</option>
                                    <option value="Казахстан">{lang === 'ru' ? 'Казахстан' : 'Kazakhstan'}</option>
                                    <option value="Камбоджа">{lang === 'ru' ? 'Камбоджа' : 'Cambodia'}</option>
                                    <option value="Камерун">{lang === 'ru' ? 'Камерун' : 'Cameroon'}</option>
                                    <option value="Канада">{lang === 'ru' ? 'Канада' : 'Canada'}</option>
                                    <option value="Катар">{lang === 'ru' ? 'Катар' : 'Qatar'}</option>
                                    <option value="Кения">{lang === 'ru' ? 'Кения' : 'Kenya'}</option>
                                    <option value="Кипр">{lang === 'ru' ? 'Кипр' : 'Cyprus'}</option>
                                    <option value="Киргизия">{lang === 'ru' ? 'Киргизия' : 'Kyrgyzstan'}</option>
                                    <option value="Кирибати">{lang === 'ru' ? 'Кирибати' : 'Kiribati'}</option>
                                    <option value="Китай">{lang === 'ru' ? 'Китай' : 'China'}</option>
                                    <option value="КНДР">{lang === 'ru' ? 'КНДР' : 'North Korea'}</option>
                                    <option value="Колумбия">{lang === 'ru' ? 'Колумбия' : 'Colombia'}</option>
                                    <option value="Коморы">{lang === 'ru' ? 'Коморы' : 'Comoros'}</option>
                                    <option value="Конго">{lang === 'ru' ? 'Конго' : 'Congo'}</option>
                                    <option
                                        value="Конго, Демократическая Республика">{lang === 'ru' ? 'Конго, Демократическая Республика' : 'Democratic Republic of the Congo'}</option>
                                    <option value="Коста-Рика">{lang === 'ru' ? 'Коста-Рика' : 'Costa Rica'}</option>
                                    <option value="Кот-д'Ивуар">{lang === 'ru' ? "Кот-д'Ивуар" : 'Ivory Coast'}</option>
                                    <option value="Куба">{lang === 'ru' ? 'Куба' : 'Cuba'}</option>
                                    <option value="Кувейт">{lang === 'ru' ? 'Кувейт' : 'Kuwait'}</option>
                                    <option value="Кюрасао">{lang === 'ru' ? 'Кюрасао' : 'Curaçao'}</option>
                                    <option value="Лаос">{lang === 'ru' ? 'Лаос' : 'Laos'}</option>
                                    <option value="Латвия">{lang === 'ru' ? 'Латвия' : 'Latvia'}</option>
                                    <option value="Лесото">{lang === 'ru' ? 'Лесото' : 'Lesotho'}</option>
                                    <option value="Либерия">{lang === 'ru' ? 'Либерия' : 'Liberia'}</option>
                                    <option value="Ливан">{lang === 'ru' ? 'Ливан' : 'Lebanon'}</option>
                                    <option value="Ливия">{lang === 'ru' ? 'Ливия' : 'Libya'}</option>
                                    <option value="Литва">{lang === 'ru' ? 'Литва' : 'Lithuania'}</option>
                                    <option
                                        value="Лихтенштейн">{lang === 'ru' ? 'Лихтенштейн' : 'Liechtenstein'}</option>
                                    <option value="Люксембург">{lang === 'ru' ? 'Люксембург' : 'Luxembourg'}</option>
                                    <option value="Маврикий">{lang === 'ru' ? 'Маврикий' : 'Mauritius'}</option>
                                    <option value="Мавритания">{lang === 'ru' ? 'Мавритания' : 'Mauritania'}</option>
                                    <option value="Мадагаскар">{lang === 'ru' ? 'Мадагаскар' : 'Madagascar'}</option>
                                    <option value="Майотта">{lang === 'ru' ? 'Майотта' : 'Mayotte'}</option>
                                    <option value="Макао">{lang === 'ru' ? 'Макао' : 'Macau'}</option>
                                    <option value="Малави">{lang === 'ru' ? 'Малави' : 'Malawi'}</option>
                                    <option value="Малайзия">{lang === 'ru' ? 'Малайзия' : 'Malaysia'}</option>
                                    <option value="Мали">{lang === 'ru' ? 'Мали' : 'Mali'}</option>
                                    <option value="Мальдивы">{lang === 'ru' ? 'Мальдивы' : 'Maldives'}</option>
                                    <option value="Мальта">{lang === 'ru' ? 'Мальта' : 'Malta'}</option>
                                    <option value="Марокко">{lang === 'ru' ? 'Марокко' : 'Morocco'}</option>
                                    <option value="Мартиника">{lang === 'ru' ? 'Мартиника' : 'Martinique'}</option>
                                    <option
                                        value="Маршалловы Острова">{lang === 'ru' ? 'Маршалловы Острова' : 'Marshall Islands'}</option>
                                    <option value="Мексика">{lang === 'ru' ? 'Мексика' : 'Mexico'}</option>
                                    <option value="Микронезия">{lang === 'ru' ? 'Микронезия' : 'Micronesia'}</option>
                                    <option value="Мозамбик">{lang === 'ru' ? 'Мозамбик' : 'Mozambique'}</option>
                                    <option value="Молдова">{lang === 'ru' ? 'Молдова' : 'Moldova'}</option>
                                    <option value="Монако">{lang === 'ru' ? 'Монако' : 'Monaco'}</option>
                                    <option value="Монголия">{lang === 'ru' ? 'Монголия' : 'Mongolia'}</option>
                                    <option value="Монтсеррат">{lang === 'ru' ? 'Монтсеррат' : 'Montserrat'}</option>
                                    <option value="Мьянма">{lang === 'ru' ? 'Мьянма' : 'Myanmar'}</option>
                                    <option value="Намибия">{lang === 'ru' ? 'Намибия' : 'Namibia'}</option>
                                    <option value="Науру">{lang === 'ru' ? 'Науру' : 'Nauru'}</option>
                                    <option value="Непал">{lang === 'ru' ? 'Непал' : 'Nepal'}</option>
                                    <option value="Нигер">{lang === 'ru' ? 'Нигер' : 'Niger'}</option>
                                    <option value="Нигерия">{lang === 'ru' ? 'Нигерия' : "Nigeria"}</option>

                                    <option value="Нидерланды">{lang === 'ru' ? 'Нидерланды' : "Netherlands"}</option>
                                    <option value="Никарагуа">{lang === 'ru' ? 'Никарагуа' : "Nicaragua"}</option>
                                    <option value="Ниуэ">{lang === 'ru' ? 'Ниуэ' : "Niue"}</option>
                                    <option value="Новая Зеландия">{lang === 'ru' ? 'Новая Зеландия' : "New Zealand"}</option>
                                    <option value="Новая Каледония">{lang === 'ru' ? 'Новая Каледония' : "New Caledonia"}</option>
                                    <option value="Норвегия">{lang === 'ru' ? 'Норвегия' : "Norway"}</option>
                                    <option value="Объединенные Арабские Эмираты">{lang === 'ru' ? 'Объединенные Арабские Эмираты' : "United Arab Emirates"}
                                    </option>
                                    <option value="Оман">{lang === 'ru' ? 'Оман' : "Oman"}</option>
                                    <option value="Остров Мэн">{lang === 'ru' ? 'Остров Мэн' : "Isle of Man"}</option>
                                    <option value="Остров Норфолк">{lang === 'ru' ? 'Остров Норфолк' : "Norfolk Island"}</option>
                                    <option value="Острова Кайман">{lang === 'ru' ? 'Каймановы Острова' : "Cayman Islands"}</option>
                                    <option value="Острова Кука">{lang === 'ru' ? 'Острова Кука' : "Cook Islands"}</option>
                                    <option value="Острова Теркс и Кайкос">{lang === 'ru' ? 'Острова Теркс и Кайкос' : "Turks and Caicos Islands"}</option>
                                    <option value="Пакистан">{lang === 'ru' ? 'Пакистан' : "Pakistan"}</option>
                                    <option value="Палау">{lang === 'ru' ? 'Палау' : "Palau"}</option>
                                    <option value="Палестинская автономия">{lang === 'ru' ? 'Палестинская автономия' : "Palestine State"}</option>
                                    <option value="Панама">{lang === 'ru' ? 'Панама' : "Panama"}</option>
                                    <option value="Папуа - Новая Гвинея">{lang === 'ru' ? 'Папуа - Новая Гвинея' : "Papua New Guinea"}</option>
                                    <option value="Парагвай">{lang === 'ru' ? 'Парагвай' : "Paraguay"}</option>
                                    <option value="Перу">{lang === 'ru' ? 'Перу' : "Peru"}</option>
                                    <option value="Питкерн">{lang === 'ru' ? 'Питкерн' : "Pitcairn Islands"}</option>
                                    <option value="Польша">{lang === 'ru' ? 'Польша' : "Poland"}</option>
                                    <option value="Португалия">{lang === 'ru' ? 'Португалия' : "Portugal"}</option>
                                    <option value="Пуэрто-Рико">{lang === 'ru' ? 'Пуэрто-Рико' : "Puerto Rico"}</option>
                                    <option value="Реюньон">{lang === 'ru' ? 'Реюньон' : "La Reunion"}</option>
                                    <option value="Россия">{lang === 'ru' ? 'Россия' : "Russia"}</option>
                                    <option value="Руанда">{lang === 'ru' ? 'Руанда' : "Republic of Rwanda"}</option>
                                    <option value="Румыния">{lang === 'ru' ? 'Румыния' : "Romania"}</option>
                                    <option value="США">{lang === 'ru' ? 'США' : "USA"}</option>
                                    <option value="Сальвадор">{lang === 'ru' ? 'Сальвадор' : "El Salvador"}</option>
                                    <option value="Самоа">{lang === 'ru' ? 'Самоа' : "Samoa"}</option>
                                    <option value="Сан-Марино">{lang === 'ru' ? 'Сан-Марино' : "San Marino"}</option>
                                    <option value="Сан-Томе и Принсипи">{lang === 'ru' ? 'Сан-Томе и Принсипи' : "Sao Tome and Principe"}</option>
                                    <option value="Саудовская Аравия">{lang === 'ru' ? 'Саудовская Аравия' : "Saudi Arabia"}</option>
                                    <option value="Свазиленд">{lang === 'ru' ? 'Эсватини' : "Eswatini"}</option>
                                    <option value="Святая Елена">{lang === 'ru' ? 'Святая Елена' : "Saint Helena"}</option>
                                    <option value="Северная Корея">{lang === 'ru' ? 'Северная Корея' : "North Korea"}</option>
                                    <option value="Северные Марианские острова">{lang === 'ru' ? 'Северные Марианские острова' : "Northern Mariana Islands"}
                                    </option>
                                    <option value="Сейшелы">{lang === 'ru' ? 'Сейшелы' : "Seychelles"}</option>
                                    <option value="Сенегал">{lang === 'ru' ? 'Сенегал' : "Senegal"}</option>
                                    <option value="Сент-Винсент">{lang === 'ru' ? 'Сент-Винсент' : "Saint Vincent"}</option>
                                    <option value="Сент-Китс и Невис">{lang === 'ru' ? 'Сент-Китс и Невис' : "Federation of Saint Kitts and Nevis"}</option>
                                    <option value="Сент-Люсия">{lang === 'ru' ? 'Сент-Люсия' : "Saint Lucia"}</option>
                                    <option value="Сент-Пьер и Микелон">{lang === 'ru' ? 'Сент-Пьер и Микелон' : "Saint Pierre and Miquelon"}</option>
                                    <option value="Сербия">{lang === 'ru' ? 'Сербия' : "Serbia"}</option>
                                    <option value="Сингапур">{lang === 'ru' ? 'Сингапур' : "Singapore"}</option>
                                    <option value="Синт-Мартен">{lang === 'ru' ? 'Синт-Мартен' : "Sint Maarten"}</option>
                                    <option value="Сирийская Арабская Республика">{lang === 'ru' ? 'Сирия' : "Syria"}
                                    </option>
                                    <option value="Словакия">{lang === 'ru' ? 'Словакия' : "Slovakia"}</option>
                                    <option value="Словения">{lang === 'ru' ? 'Словения' : "Slovenia"}</option>
                                    <option value="Соломоновы Острова">{lang === 'ru' ? 'Соломоновы Острова' : "Solomon Islands"}</option>
                                    <option value="Сомали">{lang === 'ru' ? 'Сомали' : "Somalia"}</option>
                                    <option value="Судан">{lang === 'ru' ? 'Судан' : "Sudan"}</option>
                                    <option value="Суринам">{lang === 'ru' ? 'Суринам' : "Suriname"}</option>
                                    <option value="Сьерра-Леоне">{lang === 'ru' ? 'Сьерра-Леоне' : "Sierra Leone"}</option>
                                    <option value="Таджикистан">{lang === 'ru' ? 'Таджикистан' : "Tajikistan"}</option>
                                    <option value="Таиланд">{lang === 'ru' ? 'Таиланд' : "Thailand"}</option>
                                    <option value="Танзания">{lang === 'ru' ? 'Танзания' : "Tanzania"}</option>
                                    <option value="Того">{lang === 'ru' ? 'Того' : "Togo"}</option>
                                    <option value="Токелау">{lang === 'ru' ? 'Токелау' : "Tokelau"}</option>
                                    <option value="Тонга">{lang === 'ru' ? 'Тонга' : "Tonga"}</option>
                                    <option value="Тринидад и Тобаго">{lang === 'ru' ? 'Тринидад и Тобаго' : "Trinidad and Tobago"}</option>
                                    <option value="Тувалу">{lang === 'ru' ? 'Тувалу' : "Tuvalu"}</option>
                                    <option value="Тунис">{lang === 'ru' ? 'Тунис' : "Tunisia"}</option>
                                    <option value="Туркменистан">{lang === 'ru' ? 'Туркменистан' : "Turkmenistan"}</option>
                                    <option value="Турция">{lang === 'ru' ? 'Турция' : "Turkey"}</option>
                                    <option value="Уганда">{lang === 'ru' ? 'Уганда' : "Uganda"}</option>
                                    <option value="Узбекистан">{lang === 'ru' ? 'Узбекистан' : "Uzbekistan"}</option>
                                    <option value="Украина">{lang === 'ru' ? 'Украина' : "Ukraine"}</option>
                                    <option value="Уоллис и Футуна">{lang === 'ru' ? 'Уоллис и Футуна' : "Wallis and Futuna"}</option>
                                    <option value="Уругвай">{lang === 'ru' ? 'Уругвай' : "Uruguay"}</option>
                                    <option value="Фарерские острова">{lang === 'ru' ? 'Фарерские острова' : "Faroe Islands"}</option>
                                    <option value="Фиджи">{lang === 'ru' ? 'Фиджи' : "Fiji"}</option>
                                    <option value="Филиппины">{lang === 'ru' ? 'Филиппины' : "Philippines"}</option>
                                    <option value="Финляндия">{lang === 'ru' ? 'Финляндия' : "Finland"}</option>
                                    <option value="Фолклендские острова">{lang === 'ru' ? 'Фолклендские острова' : "Falkland Islands"}</option>
                                    <option value="Франция">{lang === 'ru' ? 'Франция' : "France"}</option>
                                    <option value="Французская Гвиана">{lang === 'ru' ? 'Французская Гвиана' : "French Guiana"}</option>
                                    <option value="Французская Полинезия">{lang === 'ru' ? 'Французская Полинезия' : "French Polynesia"}</option>
                                    <option value="Хорватия">{lang === 'ru' ? 'Хорватия' : "Croatia"}</option>
                                    <option value="Центрально-Африканская Республика">{lang === 'ru' ? 'Центрально-Африканская Республика' : "Central African Republic"}
                                    </option>
                                    <option value="Чад">{lang === 'ru' ? 'Чад' : "Chad"}</option>
                                    <option value="Черногория">{lang === 'ru' ? 'Черногория' : "Montenegro"}</option>
                                    <option value="Чехия">{lang === 'ru' ? 'Чехия' : "Czech Republic"}</option>
                                    <option value="Чили">{lang === 'ru' ? 'Чили' : "Chile"}</option>
                                    <option value="Швейцария">{lang === 'ru' ? 'Швейцария' : "Switzerland"}</option>
                                    <option value="Швеция">{lang === 'ru' ? 'Швеция' : "Sweden"}</option>
                                    <option value="Шри-Ланка">{lang === 'ru' ? 'Шри-Ланка' : "Sri Lanka"}</option>
                                    <option value="Эквадор">{lang === 'ru' ? 'Эквадор' : "Ecuador"}</option>
                                    <option value="Экваториальная Гвинея">{lang === 'ru' ? 'Экваториальная Гвинея' : "Equatorial Guinea"}</option>
                                    <option value="Эритрея">{lang === 'ru' ? 'Эритрея' : "Eritrea"}</option>
                                    <option value="Эстония">{lang === 'ru' ? 'Эстония' : "Estonia"}</option>
                                    <option value="Эфиопия">{lang === 'ru' ? 'Эфиопия' : "Ethiopia"}</option>
                                    <option value="Южная Корея">{lang === 'ru' ? 'Южная Корея' : "South Korea"}</option>
                                    <option value="Южно-Африканская Республика">{lang === 'ru' ? 'ЮАР' : "South Africa"}
                                    </option>
                                    <option value="Южный Судан">{lang === 'ru' ? 'Южный Судан' : "South Sudan"}</option>
                                    <option value="Ямайка">{lang === 'ru' ? 'Ямайка' : "Jamaica"}</option>
                                    <option value="Япония">{lang === 'ru' ? 'Япония' : "Japan"}</option>

                                </Field>
                            </div>
                            {errors.country && touched.country ? (
                                <div className={s.error}>{errors.country}</div>
                            ) : null}
                        </label>
                        <label>
                            <div>
                                {lang === 'ru' ? 'Город' : 'City'}
                            </div>
                            <div>
                                <Field
                                    required={true}
                                    type="text"
                                    style={isWrong(errors.city, touched.city)}
                                    name="city"/>
                            </div>
                            {errors.city && touched.city ? (
                                <div className={s.error}>{errors.city}</div>
                            ) : null}
                        </label>
                        <label>
                            <div>
                                {lang === 'ru' ? 'Организация' : 'Organization'}
                            </div>
                            <div>
                                <Field
                                    required={true}
                                    type="text"
                                    style={isWrong(errors.organization, touched.organization)}
                                    name="organization"/>
                            </div>
                            {errors.organization && touched.organization ? (
                                <div className={s.error}>{errors.organization}</div>
                            ) : null}
                        </label>


                        <button type="submit">
                            {send}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
export default ProfileEdit;

function isWrong(errors, touched) {
    return errors && touched ? {border: "1px solid red"} : {border: "1px solid #CCCCCC"};
}