export const CabinetThemes = {
    edit: {
        components: {
            Button: {
                defaultBg: 'rgba(56, 95, 235, 1)',
                defaultHoverBg: 'white',
                defaultActiveBg: 'white',
                defaultColor: "white",
                defaultActiveColor: "rgba(56, 95, 235, 1)",
                defaultHoverColor: "rgba(56, 95, 235, 1)",
                colorBorder: "none",
                defaultHoverBorderColor: "rgba(56, 95, 235, 1)",
                defaultActiveBorderColor: "none",
                contentFontSize: "20px",
                paddingBlock: "25px",
                paddingInline: "25px"

            }
        }
    },
    logout: {
        components: {
            Button: {
                defaultBg: 'red',
                defaultHoverBg: 'white',
                defaultActiveBg: 'white',
                defaultColor: "white",
                defaultActiveColor: "red",
                defaultHoverColor: "red",
                colorBorder: "none",
                defaultHoverBorderColor: "red",
                defaultActiveBorderColor: "none",
                contentFontSize: "20px",
                paddingBlock: "25px",
                paddingInline: "25px"

            }
        }
    },
}