import s from "./Login.module.css"
import {useEffect, useState} from "react";
import {Button, ConfigProvider, Form, Input} from "antd";
import {HeaderThemes} from "../../themes/HeaderThemes";
import {login, register} from "../../Redux/auth/authActions";
import {useDispatch, useSelector} from "react-redux";

const LoginModal = ({setIsModalOpen, onCancel}) => {
    const {lang} = useSelector(state => state.lang);
    const [mode, setMode] = useState('login');

    return (
        <div className={s.modal}>
            <div className={s.nav}>
                <ConfigProvider theme={mode === 'login' ? HeaderThemes.modalActive : HeaderThemes.modalDisable}>
                    <Button onClick={() => setMode('login')}>{lang === 'ru' ? 'Вход' : 'Log In'}</Button>
                </ConfigProvider>
                <ConfigProvider theme={mode === 'reg' ? HeaderThemes.modalActive : HeaderThemes.modalDisable}>
                    <Button onClick={() => setMode('reg')}>{lang === 'ru' ? 'Регистрация' : 'Registration'}</Button>
                </ConfigProvider>
            </div>
            {mode === 'login' && <LoginForm setMode={setMode} setIsModalOpen={setIsModalOpen}/>}
            {mode === 'reg' && <RegisterForm onCancel={onCancel} setIsModalOpen={setIsModalOpen}/>}
            {mode === 'reset' && <ResetPasswordForm onCancel={onCancel} setIsModalOpen={setIsModalOpen}/>}
        </div>
    )
}
export default LoginModal

const onFinishFailed = (errorInfo) => {

};
const LoginForm = ({setIsModalOpen, setMode}) => {
    const {error, isAuthenticated} = useSelector(state => state.auth);
    useEffect(() => {
        if (isAuthenticated) {
            setIsModalOpen(false);
        }
    }, [isAuthenticated, setIsModalOpen]);
    const dispatch = useDispatch();
    let formdata = new FormData();
    const {lang} = useSelector(state => state.lang);
    return <ConfigProvider theme={HeaderThemes.loginForm}>
        <Form className={s.form}
              name="login"
              layout="vertical"
              onFinish={async (values) => {
                  formdata.append("username", values.username);
                  formdata.append("password", values.password);
                  let myHeaders = new Headers();
                  let requestOptions = {
                      method: 'POST',
                      headers: myHeaders,
                      body: formdata,
                      redirect: 'follow',
                      withCredentials: true
                  }

                  dispatch(login(requestOptions))
              }}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              requiredMark={false}
        >
            <Form.Item
                label={lang === 'ru' ? "Почта пользователя" : "E-mail"}
                name="username"
                rules={[
                    {
                        required: true,
                        message: lang === 'ru' ? "Введите почту!" : 'Please input your username!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label={lang === 'ru' ? "Пароль" : "Password"}
                name="password"
                rules={[
                    {
                        required: true,
                        message: lang === 'ru' ? "Введите пароль!!" : 'Please input your password!',
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item

            >
                <div style={{marginTop: "30px"}} className={s.nav}>
                    <Button htmlType="submit">
                        {lang === 'ru' ? "Войти" : 'Submit'}
                    </Button>
                    {error && <div className={s.error}>{error}</div>}
                </div>

            </Form.Item>
            <div>
                <Button onClick={() => setMode('reset')}
                        type="link">{lang === 'ru' ? "Забыли пароль?" : 'Forget Password'}</Button>
            </div>
        </Form>
    </ConfigProvider>
}
const RegisterForm = ({setIsModalOpen, onCancel}) => {
    const {registerError, isRegistered} = useSelector(state => state.auth);
    const [newMail, setNewMail] = useState('')
    const [close, setClose] = useState(false)
    useEffect(() => {
        if (close) {
            setIsModalOpen(false);
        }
    }, [close, setIsModalOpen]);
    const dispatch = useDispatch();
    const {lang} = useSelector(state => state.lang);
    let formdata = new FormData();
    if (isRegistered) {
        return (
            <ConfigProvider theme={HeaderThemes.loginForm}>
                <div className={s.info}>
                    <h2>
                        {lang === 'ru' ? "Регистрация прошла успешно, подтвердите аккаунт в письме" : 'Registration was successful, confirm the account in the email'}
                    </h2>
                    {lang === 'ru' ? <p>
                        Мы отравили вам письмо на почту {newMail}<br/>
                        Если письма не видно, проверьте спам, рассылки или нежелательную почту
                    </p> :
                        <p>
                            We have sent you an email {newMail}<br/>
                            If the email is not visible, check for spam, mailing lists or junk mail
                        </p>
                    }
                    <div style={{marginTop: "30px"}} className={s.nav}>
                        <Button onClick={onCancel}>
                            {lang === 'ru' ? "Подтвердить" : 'Submit'}
                        </Button>
                    </div>
                </div>
            </ConfigProvider>
        )
    }
    return <ConfigProvider theme={HeaderThemes.loginForm}>
        <Form className={s.form}
              name="basic"
              layout="vertical"
              onFinish={async (values) => {
                  formdata.append("username", values.email);
                  formdata.append("email", values.email);
                  formdata.append("password", values.password);
                  let myHeaders = new Headers();
                  let requestOptions = {
                      method: 'POST',
                      headers: myHeaders,
                      body: formdata,
                      redirect: 'follow',
                      withCredentials: true
                  }
                  dispatch(register(requestOptions))
                  setNewMail(values.email)
              }}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              requiredMark={false}
        >
            <Form.Item
                name="email"
                label={lang === 'ru' ? "Почта пользователя" : "E-mail"}
                rules={[
                    {
                        type: 'email',
                        message: lang === 'ru' ? "Введенный адрес электронной почты неверен!" : 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: lang === 'ru' ? "Введите свой E-mail!" : 'Please input your E-mail!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name="password"
                label={lang === 'ru' ? "Пароль" :"Password"}
                rules={[
                    {
                        required: true,
                        message: lang === 'ru' ? "Введите свой пароль!" : 'Please input your password!',
                    },
                ]}
                hasFeedback
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item
                name="confirm"
                label={lang === 'ru' ? "Подтвердите Пароль" : "Confirm Password"}
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: lang === 'ru' ? "Подтвердите свой пароль!" : 'Please confirm your password!',
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(lang === 'ru' ? "Пароли должны совпадать!" : 'The new password that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item

            >
                <div style={{marginTop: "30px"}} className={s.nav}>
                    <Button htmlType="submit">
                        {lang === 'ru' ? "Подтвердить" : 'Submit'}
                    </Button>
                    {registerError && <div className={s.error}>{registerError}</div>}
                </div>

            </Form.Item>
        </Form>
    </ConfigProvider>
}

const ResetPasswordForm = ({onCancel}) => {
    const [errorM, setErrorM] = useState('')
    const [sent, setSent] = useState(false)
    const {lang} = useSelector(state => state.lang);
    let formdata = new FormData();
    if (sent === true) {
        return (
            <ConfigProvider theme={HeaderThemes.loginForm}>
                <div className={s.info}>
                    <h2>
                        {lang === 'ru' ? 'Проверьте почту' : 'Check your E-mail'}
                    </h2>
                    <div>
                        {lang === 'ru' ? 'Мы отравили вам письмо для восстановления доступа на почту' : 'We have sent you an email to restore access to your email'}
                    </div>
                    <div style={{marginTop: "30px"}} className={s.nav}>
                        <Button onClick={onCancel}>
                            {lang === 'ru' ? "Подтвердить" : 'Submit'}
                        </Button>
                    </div>
                </div>
            </ConfigProvider>
        )
    }
    return <ConfigProvider theme={HeaderThemes.loginForm}>
        <Form className={s.form}
              name="login"
              layout="vertical"
              onFinish={async (values) => {
                  formdata.append("email", values.email);
                  let myHeaders = new Headers();
                  let requestOptions = {
                      method: 'POST',
                      headers: myHeaders,
                      body: formdata,
                      redirect: 'follow',
                      withCredentials: true
                  }
                  fetch("https://bricsyoung.com/api/auth/users/reset_password/", requestOptions)
                      .then(response =>
                          response.text()
                      )
                      .then(result => {

                          let resp = result ? JSON.parse(result) : ''
                          if (resp[0]) {
                              setErrorM(resp[0])
                          } else if (resp && resp !== "") {
                              setErrorM(lang === 'ru' ? "Ошибка! Попробуйте поменять данные или обратитесь в тех. поддержку" : "Error! Try to change the data or contact technical support")
                          } else {
                              setSent(true)
                          }
                      })
              }}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              requiredMark={false}
        >
            <Form.Item
                name="email"
                label={lang === 'ru' ? "Почта пользователя" : "E-mail"}
                rules={[
                    {
                        type: 'email',
                        message: lang === 'ru' ? "Введенный адрес электронной почты неверен!" : 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: lang === 'ru' ? "Введите свой E-mail!" : 'Please input your E-mail!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item

            >
                <div style={{marginTop: "30px"}} className={s.nav}>
                    <Button htmlType="submit">
                        {lang === 'ru' ? "Подтвердить" : 'Submit'}
                    </Button>
                    <div className={s.error}>{errorM}</div>
                </div>

            </Form.Item>
        </Form>
    </ConfigProvider>
}