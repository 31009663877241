import {useSelector} from "react-redux";
import s from "./Forms.module.css"
import {useMediaQuery} from "react-responsive";

const KolbaForm = () => {
    const {lang} = useSelector(state => state.lang);
    const minHeight = useMediaQuery({query: '(min-width: 620px)'})
    const secondHeight = useMediaQuery({minWidth: 520, maxWidth: 619})
    const thirdHeight = useMediaQuery({minWidth: 390, maxWidth: 519})
    const lastHeight = useMediaQuery({query: '(max-width: 389px)'})

    return (
        <div className={s.form}>
            <h1>{lang === "ru" ? 'Подача заявки на премию "Колба-2024"' : 'Submitting an application for the Kolba-2024 award'}</h1>
            <script src="https://forms.yandex.ru/_static/embed.js"></script>
            <iframe src="https://forms.yandex.ru/u/66e43670e010db612e2fb86b/?iframe=1" frameBorder="0"
                    name="ya-form-66e43670e010db612e2fb86b" height={getHeight(minHeight, secondHeight, thirdHeight, lastHeight)} width="100%"></iframe>
        </div>
    )
}
export default KolbaForm

function getHeight(min, second, third, last) {
    if (min) {
        return "5200"
    }
    if (second) {
        return "5500"
    }
    if (third) {
        return "6000"
    }
    if (last) {
        return "7200"
    }
}