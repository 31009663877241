import s from "./Flags.module.css";
import brazil from "../../../assets/Main/brazil.png"
import russia from "../../../assets/Main/russia.png"
import india from "../../../assets/Main/india.png"
import china from "../../../assets/Main/china.png"
import sa from "../../../assets/Main/sa.png"
import uae from "../../../assets/Main/uae.png"
import iran from "../../../assets/Main/iran.png"
import egypt from "../../../assets/Main/egypt.png"
import ethiopia from "../../../assets/Main/ethi.png"
import saudi from "../../../assets/Main/saudi.png"
import {useSelector} from "react-redux";
import { NavLink } from "react-router-dom";

const FlagsBlock = () => {
    const {lang} = useSelector(state => state.lang);
    return (
        <div className={s.flags}>
            <div className={s.info}>
                <h2>{lang === "ru" ? "Страны-участницы" : "Member states"}</h2>
                <Flags/>
            </div>
        </div>
    )
}

const Flags = () => {
    return (
        <div className={s.flagFlex}>
            {
                counties.map((el) =>
                    <Flag key={el.enTitle} {...el}/>
                )
            }
        </div>
    )
}

const Flag = ({title, flag, enTitle, link=1}) => {
    const {lang} = useSelector(state => state.lang);
    return (
        <div className={s.flagUrl}>
            <NavLink to={link}>

            <img alt={lang === "ru" ? title : enTitle} src={flag}/>
            </NavLink>
            <div>
                {lang === "ru" ? title : enTitle}
            </div>
        </div>
    )
}
export default FlagsBlock

const counties = [
    {
        title: "Россия",
        enTitle: "Russia",
        flag: russia,
        link: "country/10"
    },
    {
        title: "Саудовская Аравия",
        enTitle: "Saudi Arabia",
        flag: saudi,
         link: "country/4"
    },
    {
        title: "ЮАР",
        enTitle: "Republic of South Africa",
        flag: sa,
         link: "country/9"
    },
    {
        title: "ОАЭ",
        enTitle: "United Arab Emirates",
        flag: uae,
        link: "country/6"
    },
    {
        title: "Бразилия",
        enTitle: "Brazil",
        flag: brazil,
        link: "country/1"
    },
    {
        title: "Китай",
        enTitle: "China",
        flag: china,
         link: "country/2"
    },
    {
        title: "Египет",
        enTitle: "Egypt",
        flag: egypt,
         link: "country/3"
    },
    {
        title: "Эфиопия",
        enTitle: "Ethiopia",
        flag: ethiopia,
         link: "country/5"
    },
    {
        title: "Индия",
        enTitle: "India",
        flag: india,
         link: "country/8"
    },
    {
        title: "Иран",
        enTitle: "Iran",
        flag: iran,
         link: "country/7"
    }
]