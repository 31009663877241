import {lazy, Suspense} from 'react';

const VideoBlock = lazy(() =>
    import('../../Components/Video/VideoBlock')
);

const VideoGallery = () => {
    return (
        <Suspense>
            {videoArray.map((el) =>
                <VideoBlock {...el}/>
            )}
        </Suspense>
    )
}
export default VideoGallery

const videoArray = [
    {
        title: "BRICS Youth Radio – 30.07.2024 – Ikanyeng Masinamela (South Africa)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: "8Gr_pf3CxUE"
    },
    {
        title: "BRICS Youth Radio – 30.07.2024 – Henrique Domingues (Brazil)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: "asXX23whPOQ"
    },
    {
        title: "BRICS Youth Radio – 30.07.2024 – Bassel Shahien (Egypt)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: "hEEM0JkkeKQ"
    },
    {
        title: "BRICS Youth Radio – 30.07.2024 – Akil Mohammad (India)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: "N80LxF-mOdE"
    },
    {
        title: "BRICS Youth Radio – 30.07.2024 – Luan Medeiros (Brazil)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: ""
    },
    {
        title: "BRICS Youth Radio – 30.07.2024 – Asanda Luwaca (South Africa)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: "ARx0fKA7ua8"
    },
    {
        title: "Молодежное Радио БРИКС – 31.07.2024 – Диана Ковела (Россия)",
        description: <>
            В течение 5 дней на Молодежном Саммите БРИКС в Ульяновске работало Радио БРИКС – уникальная площадка для
            общения и обмена идеями молодых лидеров из стран БРИКС!
            <p></p>
            В эфире радио звучали интервью с организаторами, участниками и экспертами Саммита о культуре, традициях,
            молодежной политике и молодежных проектах стран Альянса.
        </>,
        videoId: "O6ZspzRIWK8"
    },
    {
        title: "BRICS Youth Radio – 31.07.2024 – Fatemehsadat Nazeri (Iran)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: ""
    },
    {
        title: "Молодежное Радио БРИКС – 31.07.2024 – Юлия Рузанкина (Россия)",
        description: <>
            В течение 5 дней на Молодежном Саммите БРИКС в Ульяновске работало Радио БРИКС – уникальная площадка для
            общения и обмена идеями молодых лидеров из стран БРИКС!
            <p></p>
            В эфире радио звучали интервью с организаторами, участниками и экспертами Саммита о культуре, традициях,
            молодежной политике и молодежных проектах стран Альянса.
        </>,
        videoId: "MAc8Ti7G0w0"
    },
    {
        title: "Молодежное Радио БРИКС – 31.07.2024 – Даниил Пастухов (Россия)",
        description: <>
            В течение 5 дней на Молодежном Саммите БРИКС в Ульяновске работало Радио БРИКС – уникальная площадка для
            общения и обмена идеями молодых лидеров из стран БРИКС!
            <p></p>
            В эфире радио звучали интервью с организаторами, участниками и экспертами Саммита о культуре, традициях,
            молодежной политике и молодежных проектах стран Альянса.
        </>,
        videoId: "qmrucjzOyKo"
    },
    {
        title: "Молодежное Радио БРИКС – 31.07.2024 – Анна Субботина (Россия)",
        description: <>
            В течение 5 дней на Молодежном Саммите БРИКС в Ульяновске работало Радио БРИКС – уникальная площадка для
            общения и обмена идеями молодых лидеров из стран БРИКС!
            <p></p>
            В эфире радио звучали интервью с организаторами, участниками и экспертами Саммита о культуре, традициях,
            молодежной политике и молодежных проектах стран Альянса.
        </>,
        videoId: "dO37rAnIjSM"
    },
    {
        title: "BRICS Youth Radio – 31.07.2024 – Zainab Alturaiki (Saudi Arabia)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: ""
    },
    {
        title: "BRICS Youth Radio – 31.07.2024 – Mzwandile Thakhudi (South Africa)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: ""
    },
    {
        title: "BRICS Youth Radio – 31.07.2024 – Madhish Parikh (India)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: ""
    },
    {
        title: "BRICS Youth Radio – 31.07.2024 – Likke Yimer (Ethiopia)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: ""
    },
    {
        title: "BRICS Youth Radio – 31.07.2024 – Maryam Alshehhi (UAE)",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: ""
    },
    {
        title: "Plenary session",
        description: <>
            </>,
        videoId: "7WUaEOryPVE"
    },
    {
        title: "BRICS Youth awareness. About BRICS and for BRICS",
        description: <>
           </>,
        videoId: "QaCwbOB24GU"
    },
    {
        title: "BRICS Youth entrepreneurship — space of opportunities",
        description: <>
            For 5 days, BRICS Radio worked at the BRICS Youth Summit in Ulyanovsk – a unique platform for communication
            and exchange of ideas of young leaders from the BRICS countries!
            <p></p>
            The radio broadcast featured interviews with the organizers, participants and experts of the Summit on
            culture, traditions, youth policy and youth projects of the Alliance countries.
        </>,
        videoId: "Phqvgx2JlwQ"
    },
    {
        title: "BRICS Youth and innovations — let’s build the world of equal technological opportunities",
        description: <>
            </>,
        videoId: "5vy2ecmG1rw"
    },
    {
        title: "Youth research as a tool for influencing the future",
        description: <>
            </>,
        videoId: "mYS_0l304CM"
    },
    {
        title: "Healthy BRICS: sport as lifestyle",
        description: <></>,
        videoId: "SqokACN7rrc"
    },
    {
        title: "Uniting BRICS Youth’ hearts",
        description: <></>,
        videoId: "JnUCXSUpzYU"
    },
    {
        title: "BRICS Young Entrepreneur: how can a foreigner do business in Russia",
        description: <></>,
        videoId: "VlNoizXXsMc"
    },
    {
        title: "Reading out the declaration. Closing remarks by the Ministers",
        description: <></>,
        videoId: "4jTJXPkfr4s"
    },
]