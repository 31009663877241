export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_DATA_ERROR = "SET_USER_DATA_ERROR";
export const SET_RU = 'SET_RU'
export const SET_EN = 'SET_EN'
export const RESET_REGISTER = 'RESET_REGISTER'