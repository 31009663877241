import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    REGISTER_REQUEST,
    REGISTER_FAILURE,
    REGISTER_SUCCESS, RESET_REGISTER,

} from '../types';

export const login = (requestOptions) => {

    return async dispatch => {
        dispatch({type: LOGIN_REQUEST});
        try {
            const response = await fetch("https://bricsyoung.com/api/auth/jwt/create/", requestOptions);
            const data = await response.json();
            if (data.access) {
                localStorage.setItem("access", data.access)
                dispatch({type: LOGIN_SUCCESS});

            } else if (data.detail){
                throw new Error(data.detail);
            } else {
                throw new Error('Error!');
            }
        } catch (error) {
            dispatch({type: LOGIN_FAILURE, payload: error.message});
        }
    };
};
export const register = (requestOptions) => {

    return async dispatch => {
        dispatch({type: REGISTER_REQUEST});
        try {
            const response = await fetch("https://bricsyoung.com/api/auth/users/", requestOptions);
            const data = await response.json();
            if (data.id && data.username && data.email) {
                dispatch({type: REGISTER_SUCCESS});

            } else if (data.password){
                throw new Error(data.password);
            } else if (data.username){
                throw new Error(data.username);
            } else if (data.email){
                throw new Error(data.email);
            } else {
                throw new Error('Error!');
            }
        } catch (error) {
            dispatch({type: REGISTER_FAILURE, payload: error.message});
        }
    };
};



export const resetReg = () => {
    return {type: RESET_REGISTER};
};

export const logout = () => {
    localStorage.removeItem("access")
    return {type: LOGOUT};
};