export const info = [
    {
        title: "Команда проекта",
        enTitle: "The project team",
        description: <p>
            Мы - редакторы, авторы и медиаволонтеры из 6 стран. Если вы хотите рассказать о своей стране и ее
            достижениях, присоединяйтесь к команде проекта как внештатный репортер или медиаволонтер — отправляйте
            заявку на нашу почту <a href="mailto:youthbrics73@yandex.ru">youthbrics73@yandex.ru</a>
        </p>,
        enDescription: <p>
            We are editors, authors and media volunteers from 6 countries. If you want to talk about your country and
            its achievements, join the project team as a freelance reporter or media volunteer — send a request to our
            mail <a href="mailto:youthbrics73@yandex.ru">youthbrics73@yandex.ru</a>
        </p>,
        color: 'black'
    },
    {
        title: "Потенциал медиаплатформы",
        enTitle: "The potential of the BRICS Mediaplatform",
        description: <div>
            <p>
                Находите новые возможности для личного и профессионального роста.
            </p>
            <p>
                "Анонсы" объединяют информацию об актуальных конференциях, форумах, грантах, стипендиях и стажировках, в
                которых вы можете принять участие как очно, так и онлайн.
            </p>
            <p>
                Наши корреспонденты из предоставят вам все свежие новости о странах Альянса.
            </p>
        </div>,
        enDescription: <div>
            <p>
                Find new opportunities for personal and professional growth.
            </p>
            <p>
                "Announcements" combine information about current conferences, forums, grants, scholarships and
                internships in which you can participate both in person and online.
            </p>
            <p>
                Our correspondents will provide you with all the latest news about the countries of the Alliance.
            </p>
        </div>,
        color: 'white'
    },
    {
        title: "О чем пишем?",
        enTitle: "What are we writing about?",
        description: <div>
            <p>
                Формируем позитивную повестку и собираем новости культуры, науки, образования, спорта и технологий,
                ориентируясь на интересы и запросы молодежи.
            </p>
            <p>
                Наш контент способствует молодежной кооперации между странами Альянса.</p>
        </div>,
        enDescription: <div>
            <p>
                We form a positive agenda and collect news from culture, science, education, sports and technology,
                focusing on the interests and needs of young people.
            </p>
            <p>
                Our content promotes youth cooperation between the
                countries of the Alliance.</p>
        </div>,
        color: 'white'
    },
    {
        title: "Мы постоянно на связи",
        enTitle: "Always in touch",
        description: <div>
            <p>
                Через форму обратной связи мы оперативно отвечаем на запросы от молодежи из стран БРИКС. Главная цель
                Медиаплатформы БРИКС - объединять молодежь и создавать условия для реализации совместных инициатив.</p>
        </div>,
        enDescription: <div>
            <p>
                Through the feedback form, we promptly respond to requests from young people from the BRICS countries.
                The main goal of the BRICS MediaPlatform is to unite young people and create conditions for the
                implementation of joint initiatives.</p>
        </div>,
        color: 'white'
    },
]