import s from "./Youtube.module.css";
import { Button, ConfigProvider } from "antd";
import { MainThemes } from "../../../themes/MainThemes";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const PresentationNew = ({ lang }) => {
  return (
    <div className={s.presentationNew}>
      <ConfigProvider theme={MainThemes.youtube}>
        <Button
          style={{ whiteSpace: "normal", height: "auto" }}
          target="_blank"
          href={
            "https://bricsyoung.com/media/media/behavior/PROGRAMME_for_media_platform_5.pdf"
          }
        >
          {lang === "ru"
            ? "Скачать программу мероприятия"
            : "Download Event Program"}
        </Button>
      </ConfigProvider>
    </div>
  );
};

export const YouTubeLiveStream = () => {
  // const isSmall = useMediaQuery({ query: "(max-width: 900px)" });
  const { lang } = useSelector((state) => state.lang);
  // const [stream, setStream] = useState("Пленарное заседание");
  // const opts = {
  //   height: "460",
  //   width: "750",
  //   playerVars: {
  //     autoplay: 2,
  //   },
  // };
  // const smallOpts = {
  //   height: "400",
  //   width: "100%",
  //   playerVars: {
  //     autoplay: 2,
  //   },
  // };
  // const onReady = (event) => {
  //   event.target.pauseVideo();
  // };

  useEffect(() => {
    const loadInfo = async () => {
      try {
        // const res = await axios.get("https://bricsyoung.com/api/stream");
        // setStream(res.data[0].header);
      } catch (e) {}
    };
    loadInfo();
  }, []);

  return (
    <div className={s.youtube}>
      <div className={s.info}>
        <div className={s.new}>
          <h2>II International youth forum BRICS+</h2>
          <div className={s.desc}>
            {lang === "ru"
              ? "24 сентября 2024 года состоится Форум по спортивной дипломатии: «Контуры многополярного мира». В нём примут участие представители  47 стран мира."
              : "On September 24, 2024, the Sports Diplomacy Forum: «Contours of a Multipolar World» will be held. Representatives from 47 countries will take part in it."}
          </div>
          <PresentationNew lang={lang} />
        </div>
        <div className={s.video}>
          {/* <YouTube videoId={getVideo(stream)} opts={isSmall ? smallOpts : opts} iframeClassName={`${s.youtubeVideo}`}
                             onReady={onReady}/> */}
        </div>
      </div>
    </div>
  );
};

// function getVideo(stream) {
//   switch (stream) {
//     case "Пленарное заседание":
//       return "7WUaEOryPVE";
//     case "Просвещение молодежи БРИКС.":
//       return "QaCwbOB24GU";
//     case "Молодежное предпринимательство БРИКС":
//       return "Phqvgx2JlwQ";
//     case "Молодежь БРИКС и инновации":
//       return "5vy2ecmG1rw";
//     case "Дискуссионная площадка":
//       return "mYS_0l304CM";
//     case "Волонтерство":
//       return "JnUCXSUpzYU"; ///
//     case "Здоровый БРИКС:":
//       return "SqokACN7rrc";
//     case "Открытый разговор молодой предприниматель":
//       return "VlNoizXXsMc";
//     case "Зачитывание декларации":
//       return "4jTJXPkfr4s"; ///
//     default:
//       return "7WUaEOryPVE";
//   }
// }

export default YouTubeLiveStream;
