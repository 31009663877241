import s from "./News.module.css"
import { useEffect, useState } from "react";
import axios from "axios";
import {NewsDisplay} from "../../Components/Main/NewsBlock";
import {useSelector} from "react-redux";

const News = () => {
    const [news, setNews] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [load, setLoad] = useState(false);
    const [filter, setFilter] = useState('');
    const {lang} = useSelector(state => state.lang);

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const loadInfo = async () => {
        try {
            const res = await axios.get("https://bricsyoung.com/api/news");
            const userData = res.data.filter(item => item.category.includes(filter));
            const response = userData.map(item => ({
                date: item.date || '',
                description: item.description || '',
                descriptionen: item.descriptionen || '',
                header: item.header || '',
                headeren: item.headeren || '',
                id: item.id || '',
                category: item.category || '',
                img: 'https://bricsyoung.com' + item.img || ''
            }));
            setNews(response);
            setLoad(true);
        } catch (error) {

        }
    }

    useEffect(() => {
        loadInfo();
    }, [filter]);

    useEffect(() => {
        const results = news.filter(p =>
            p.header.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(results);
    }, [searchTerm, news]);

    return (
        <div>
            <div className={s.main}>
                <h1>{lang === "ru" ? 'Новости' : 'News'}</h1>
                <div className={s.filter}>
                    <div className={s.categories}>
                        <span onClick={() => setFilter('')}
                              style={filter === '' ? {color: "#00B4C0"} : {color: "white"}} className={s.project}>{lang === "ru" ? 'Все новости' : 'All News'}</span>
                        <span onClick={() => setFilter('образование')}
                              style={filter === 'образование' ? {color: "#00B4C0"} : {color: "white"}}
                              className={s.edu}>{lang === "ru" ? 'Образование' : 'Education'}</span>
                        <span onClick={() => setFilter('наука')}
                              style={filter === 'наука' ? {color: "#00B4C0"} : {color: "white"}}
                              className={s.science}>{lang === "ru" ? 'Наука' : 'Science'}</span>
                        <span onClick={() => setFilter('молодёжное')}
                              style={filter === 'молодёжное' ? {color: "#00B4C0"} : {color: "white"}}
                              className={s.teen}>{lang === "ru" ? 'Молодёжное сотрудничество' : 'Youth cooperation'}</span>
                    </div>
                    <div className={s.search}>
                        <input id="searchInput" value={searchTerm} onChange={handleChange} type="text"
                               placeholder={lang === "ru" ? 'Поиск...' : 'Search...'}/>
                    </div>
                </div>
                <div className={s.news}>
                    {
                        searchTerm ?
                            <NewsDisplay response={searchResults} load={load}/>
                            :
                            <NewsDisplay response={news} load={load}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default News;

